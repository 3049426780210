import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StudyBox from "../components/StudyBox";
import { LogoutUser } from "../store/UserSlice";

function PageAccount() {
  const navigate = useNavigate();
  const { copy } = useSelector(state => state);
  const { loggedIn, participatingSurveys, privateStudies } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const signout = () => {
    dispatch(LogoutUser()).then(() => navigate("/"));
  };

  let userStudies = <></>;
  if (participatingSurveys?.length || privateStudies?.length) {
    userStudies = [];
    if (participatingSurveys)
      userStudies.push(
        [...participatingSurveys]
          .sort((a, b) => (a.surveyComplete == b.surveyComplete ? 0 : a.surveyComplete ? 1 : -1))
          .map((study, i) => <StudyBox key={study.id} study={study} selectable={"user"} />)
      );
    if (privateStudies)
      userStudies.push(
        ...privateStudies.filter(s => !s.is_participating).map((study, i) => <StudyBox key={study.id} study={study} selectable={"participating"} />)
      );
  } else {
    userStudies = <p className="fz20">{copy.no_studies_results_body}</p>;
  }

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        <section className="section_area">
          <div className="container">
            <h2 className="toptile">{copy.your_studies_title}</h2>
            <div className=" mb15">{userStudies.length ? userStudies : <p>{copy.no_studies_results_body}</p>}</div>
          </div>
        </section>
        <section className="mb15">
          <h2 className="toptile">{copy.account_management_title}</h2>
          <div className="accountDetailsBox ">
            <a href="#" className="account_detailbox mb15" onClick={() => navigate("/account/settings")}>
              <div className="account_left_icon">
                <i className="fas fa-user-cog"></i>
              </div>
              <div className="account_content">
                <h2>{copy.account_details_title || "copy.account_details_title"}</h2>
                <p className="fz20">{copy.account_details_body || "copy.account_details_body"}</p>
              </div>
              <div className="account_froword">
                <i className="fas fa-chevron-right"></i>
              </div>
            </a>
            <a href="#" className="account_detailbox mb15" onClick={() => navigate("/account/profile")}>
              <div className="account_left_icon">
                <i className="far fa-address-card"></i>
              </div>
              <div className="account_content">
                <h2>{copy.personal_details_title || "copy.personal_details_title"}</h2>
                <p className="fz20">{copy.personal_details_body || "copy.personal_details_body"}</p>
              </div>
              <div className="account_froword">
                <i className="fas fa-chevron-right"></i>
              </div>
            </a>
          </div>
          {/* <div className="accountDetailsBox">
            <br />
            <a href="#" className="account_detailbox">
              <div className="account_left_icon">
                <i className="far fa-address-card"></i>
              </div>
              <div className="account_content">
                <h2>Personal details</h2>
                <p>Personal details you have entered during studies</p>
              </div>
              <div className="account_froword">
                <i className="fas fa-chevron-right"></i>
              </div>
            </a>
            <br />
            <a href="#" className="account_detailbox">
              <div className="account_left_icon">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="account_content">
                <h2>Communication settings</h2>
                <p>Manage the emails you receive from us</p>
              </div>
              <div className="account_froword">
                <i className="fas fa-chevron-right"></i>
              </div>
            </a>
            <br />
            <a href="#" className="account_detailbox">
              <div className="account_left_icon">
                <i className="fas fa-file-alt"></i>
              </div>
              <div className="account_content">
                <h2>Terms of use</h2>
                <p>Manage the emails you receive from us</p>
              </div>
              <div className="account_froword">
                <i className="fas fa-chevron-right"></i>
              </div>
            </a>
            <br />
          </div> */}
        </section>
        <br />
        <br />
        <div className="dualBtn mb15">
          <a className="nextBtn" onClick={signout}>
            {copy.logout_button}
          </a>
        </div>
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageAccount;
