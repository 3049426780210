import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StudyBox from "../components/StudyBox";

function PageStudiesBy() {
  const { studyid } = useParams();
  const { studies } = useSelector(state => state.app);
  const study = studies.find(s => s.id == studyid);
  const navigate = useNavigate();
  return (
    <section className="section_area">
      <div className="container">
        <StudyBox study={study} />
        <div className="contentBox">
          <h2 className="mb10">About {study.user}</h2>
          <p>{study.user_biography}</p>
        </div>
        <br />
        <br />
        <h2 className="mb10">Studies by {study.user}</h2>
        {studies.reduce((arr, s, i) => {
          if (s.user_id == study.user_id) {
            arr.push(
              <div key={i} className="whiteSQbox mb15">
                <h3 className="secTitleUnder">{s.name}</h3>
                <p>{s.short_description}</p>
                <a className="nextBtn centerBtn" onClick={() => navigate(`/study/${s.id}`)}>
                  View Study
                </a>
              </div>
            );
          }
          return arr;
        }, [])}
      </div>
    </section>
  );
}

export default PageStudiesBy;
