import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function StudyBox(props) {
  const { study = {}, selectable } = props;
  const { copy } = useSelector(state => state);
  const navigate = useNavigate();
  const handleViewStudy = () => {
    if (selectable === "user") return navigate(`/study/${study.id}/user`);
    if (selectable === "participating") return navigate(study.is_participating ? `/study/${study.id}/user` : `/study/${study.id}`);
    if (selectable) return navigate(`/study/${study.id}`);
  };
  return (
    <div
      className={`studyBox ${study.surveyComplete ? "complete" : ""}`}
      onClick={handleViewStudy}
      style={{ cursor: selectable ? "pointer" : "default" }}
    >
      <img src={study.user_avatar} alt="profileImg" className="profileImg" />
      <div className="profileCont">
        <h3 className="secTitleUnder">{study.name}</h3>
        <h4>By {study.user}</h4>
        <p>{study.short_description}</p>
        {study.surveyComplete && <p>{copy.completed_study_by_title || "{copy not found : completed_study_by_title}"}</p>}
      </div>
    </div>
  );
}

export default StudyBox;
