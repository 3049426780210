import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ForgotPassword, ValidateEmail } from "../api/AppApi";
import { AppActions } from "../store/AppSlice";
import ErrorList from "./ErrorList";

function AccountForgotPass(props) {
  const { onComplete } = props;
  const emailField = useRef();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(false);
  const [state, setState] = useState("idle");
  const { reset_password_title, reset_password_body, reset_pass_try_login_link } = useSelector(state => state.copy);

  const resetPass = () => {
    if (ValidateEmail(emailField.current.value)) {
      dispatch(AppActions.setAppState("processing"));
      ForgotPassword(emailField.current.value).then(response => {
        dispatch(AppActions.setAppState("idle"));
        if (response.showErrors) {
          dispatch(AppActions.setShowErrors(response.showErrors));
        } else if (response.errors) setErrors(response.errors);
        else if (!response.success) setErrors(["an error has occured, please try again"]);
        else {
          setErrors([]);
          setState("complete");
        }
      });
    } else {
      setErrors(["please input a valid email address"]);
    }
  };

  if (state === "complete") {
    return (
      <>
        <h2 className="text-center toptile">{reset_password_title}</h2>
        <br />
        <br />
        <div className="formContainer existLogin">
          <div className="text-center inputCol fz18">An reset password link has been sent to your email</div>
          <div className="formNext">
            <button className="next_button" onClick={onComplete}>
              continue
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <h2 className="text-center toptile">{reset_password_title}</h2>
      <br />
      <br />
      <div className="formContainer existLogin">
        <div className="inputCol fz18">{reset_password_body}</div>
        <br />
        <div className="inputCol">
          <input ref={emailField} type="email" name="reset-Email" id="reset-Email" placeholder="Email Address" />
        </div>
        <br />
        <ErrorList errors={errors} />
        <div className="formNext">
          <button className="next_button" onClick={resetPass}>
            Submit
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className="loginHere text-center">
        <a href="#" onClick={onComplete}>
          {reset_pass_try_login_link}
        </a>
      </div>
    </>
  );
}

export default AccountForgotPass;
