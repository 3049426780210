import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditForumResponse } from "../api/AppApi";
import ErrorList from "../components/ErrorList";
import StudyForumThreadItem from "../components/StudyForumThreadItem";
import StudyForumThreadReply from "../components/StudyForumThreadReply";
import { AppActions } from "../store/AppSlice";
import { store } from "../store/store";

function PageForumThreadEdit() {
  const { studyid } = useParams();
  const { copy } = useSelector(state => state);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(false);
  const commentBox = useRef(false);
  const { thread, post } = useLocation().state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const returnToForumThread = () => navigate(`/study/${studyid}/forum/topic/${thread.id}`, { replace: true });
  const [newPost, setNewPost] = useState(post?.content || "");

  if (!thread || !post) return <></>;

  const validate = () => {
    const { userToken } = store.getState().user;
    if (commentBox.current.value.length) {
      dispatch(AppActions.setAppState("processing"));
      EditForumResponse(post.id, commentBox.current.value, userToken).then(response => {
        if (response.showErrors) {
          dispatch(AppActions.setShowErrors(response.showErrors));
        } else if (response.loginRequired) {
          location.state = { thread, post, onLogin: validate };
          dispatch(AppActions.setLoginOverlay(true));
        } else if (response.errors) setErrors(response.errors);
        else setSubmitted(true);
        dispatch(AppActions.setAppState("idle"));
      });
    } else {
      setErrors(["please add a comment"]);
    }
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        {submitted ? (
          <>
            <div className="commentSuccessbox text-center">
              <h2>Your post has been updated, click here to view.</h2>
            </div>
            <div className="dualBtn">
              <a className="nextBtn" onClick={returnToForumThread}>
                {copy.view_button}
              </a>
            </div>
          </>
        ) : (
          <>
            <h2 className="mb10">Edit Post</h2>
            <div className="mb15">
              <StudyForumThreadItem thread={thread} studyid={studyid} />
            </div>
            <div className="coment_replayBox mb15">
              <StudyForumThreadReply post={{ ...post, content: newPost }} studyid={studyid} noMenu />
            </div>
            <br />
            <br />
            <div className="comentpostBox mb15" style={{ marginTop: 15 }}>
              <h4>Edit your comment here:</h4>
              <form action="#">
                <textarea
                  ref={commentBox}
                  name="ComentPostBox"
                  id="ComentPostBox"
                  placeholder="Tap to type"
                  value={newPost}
                  onChange={e => setNewPost(e.target.value)}
                ></textarea>
                {errors?.length > 0 && <ErrorList errors={errors} />}
              </form>
            </div>
            <div className="dualBtn">
              <a className="nextBtn" onClick={validate}>
                Submit
              </a>
              <a className="nextBtn" onClick={returnToForumThread}>
                Back
              </a>
            </div>
          </>
        )}
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageForumThreadEdit;
