import React, { useState } from "react";
import { useSelector } from "react-redux";

import ComentMenu from "../img/more.png";

function StudyForumThreadReply(props) {
  const { post, onDelete, onEdit, noMenu } = props;
  const { copy } = useSelector(state => state);
  const [showMenu, setShowMenu] = useState(false);
  const { loggedIn, id, forumAdmin } = useSelector(state => state.user);
  const canedit = !noMenu && loggedIn && (id == post.author_id || forumAdmin);
  const timediff = Math.round((new Date().getTime() - new Date(post.updated_at).getTime()) / (1000 * 3600 * 24));
  const threadAgeLabel = timediff === 0 ? copy.today : timediff + (timediff > 1 ? ` ${copy.days_ago}` : ` ${copy.day_ago}`);
  return (
    <div className="commentBox comentReplayBox">
      {canedit && (
        <button className="comment_menu" onClick={() => setShowMenu(true)}>
          <img src={ComentMenu} alt="Coment Menu" />
        </button>
      )}
      <div className="comentReplay">
        <img src={post.author_avatar} alt="ProfilePic" className="postuser" />
        <div className="discu_user">
          <h4>{post.author_name}</h4>
          <div className="postDate">{threadAgeLabel}</div>
        </div>
      </div>
      <p>{post.content}</p>
      {showMenu && canedit && (
        <div className="miniMenu" style={{ position: "absolute", top: 0, right: 0 }} onMouseLeave={() => setShowMenu(false)}>
          <button className="btnMiniDark mb10" onClick={() => onDelete(post.id)}>
            delete
          </button>
          <button className="btnMiniDark mb10" onClick={() => onEdit(post.id)}>
            edit
          </button>
        </div>
      )}
    </div>
  );
}

export default StudyForumThreadReply;
