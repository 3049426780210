import EyeSlash from "../img/eye-slash-regular.svg";
import Eye from "../img/eye-regular.svg";
import { useState } from "react";

const PasswordField = props => {
  const [showpass, setShowPass] = useState(false);
  const toggleShowPassword = () => {
    setShowPass(showpass === false);
  };

  return (
    <div className="inputCol" style={{ width: "100%" }}>
      <input ref={props.ref} id={props.id} type={showpass ? "text" : "password"} name={props.name} style={{ width: "100%" }} />
      <img src={showpass ? Eye : EyeSlash} alt="Eye" className="inputIcon eye" onClick={toggleShowPassword} />
    </div>
  );
};

export default PasswordField;
