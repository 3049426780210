import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { AppActions } from "../../store/AppSlice";

import BackLeft from "../img/left-arrow.png";
import MoreBtn from "../img/more.png";
// import { RouterActions } from "../../store/RouterSlice";

function NavigationHeader() {
  const navigate = useNavigate();
  return (
    <div className="navigation_container">
      <div className="container">
        <div className="topnavigateContainer">
          <button className="leftArrow" onClick={() => navigate(-1)}>
            <img src={BackLeft} alt="Left Arrow" />
          </button>
          <div className="moreBtn">{/* <img src={MoreBtn} alt="More" className="more_btn" /> */}</div>
        </div>
      </div>
    </div>
  );
}

export default NavigationHeader;
