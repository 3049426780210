function ErrorList(props) {
  if (props.errors && props.errors.length) {
    return props.errors.map((error, i) => (
      <p key={i} className="app-error">
        {error}
      </p>
    ));
  }
}

export default ErrorList;
