import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteForumThread, GetForumThreads } from "../api/AppApi";
import StudyBox from "../components/StudyBox";
import { AppActions } from "../store/AppSlice";
import Pencil from "../img/pencil-alt-solid.svg";
import StudyForumThreadItem from "../components/StudyForumThreadItem";
import ErrorList from "../components/ErrorList";

function PageForum() {
  const { studyid } = useParams();
  const { copy } = useSelector(state => state);
  const [forumThreads, setForumThreads] = useState([]);
  const { studies } = useSelector(state => state.app);
  const { privateStudies } = useSelector(state => state.user);
  const { userToken } = useSelector(state => state.user);
  const [errors, setErrors] = useState(false);
  const study = [...studies, ...privateStudies].find(s => s.id == studyid);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    updateForumThreads();
  }, []);

  const updateForumThreads = () => {
    dispatch(AppActions.setAppState("processing"));
    GetForumThreads(study.forum_category_id).then(response => {
      if (response.showErrors) {
        dispatch(AppActions.setShowErrors(response.showErrors));
      } else if (response.data) {
        setForumThreads(response.data.sort((a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()));
        setErrors(false);
      } else if (response.errors) setErrors(response.errors);
      else setErrors(["error fetching forum"]);

      dispatch(AppActions.setAppState("idle"));
    });
  };

  const handleDeleteThread = threadid => {
    dispatch(AppActions.setAppState("processing"));
    DeleteForumThread(threadid, userToken).then(response => {
      if (response.showErrors) {
        dispatch(AppActions.setShowErrors(response.showErrors));
      } else if (response.errors) setErrors(response.errors);
      else if (response.data.id === threadid) {
        setForumThreads(forumThreads.filter(t => t.id != threadid));
      }
      dispatch(AppActions.setAppState("idle"));
    });
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        <StudyBox study={study} />

        <div className="contentBox">
          <h2 className="mb10">{copy.about_forum_title}</h2>
          <p>{copy.about_forum_body}</p>
          <h2 className="mb10">{copy.recent_posts_title}</h2>
          {forumThreads.length ? (
            forumThreads.map((thread, i) => (
              <div className="mb15" key={i} style={{ cursor: "pointer" }}>
                <StudyForumThreadItem thread={thread} studyid={studyid} onDelete={handleDeleteThread} />
              </div>
            ))
          ) : errors ? (
            <div className="mb15">
              <ErrorList errors={errors} />
            </div>
          ) : (
            <p className="mb15">no topics to display</p>
          )}
        </div>
        <div className="dualBtn mb15">
          {!errors && (
            <a className="white_button" onClick={() => navigate(`/study/${studyid}/forum/topic/new`)}>
              Post a topic
              <span className="editPencil">
                <img src={Pencil} alt="Pencil" />
              </span>
            </a>
          )}
          <a className="white_button" onClick={() => navigate(`/study/${studyid}`)}>
            Back to study
          </a>
        </div>
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageForum;
