import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function PageStudyUser() {
  const { studyid } = useParams();
  const navigate = useNavigate();
  const { participatingSurveys } = useSelector(state => state.user);
  const { state } = useSelector(state => state.app);
  const study = participatingSurveys.find(s => s.id == studyid);
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  const startDate = study ? new Date(study.start_participation_at) : currentDate;

  useEffect(() => {
    if (!study || state === "processing") return;
    navigate(currentDate.getTime() >= startDate.getTime() ? `/study/${studyid}/user/schedule` : `/study/${studyid}/user/confirmed`, {
      replace: true
    });
  }, [study, state]);
}

export default PageStudyUser;
