import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadApp } from "./AppSlice";

const initialState = {};

export const CopySlice = createSlice({
  name: "copy",
  initialState,
  reducers: {
    setCopy(state, action) {
      Object.keys(action.payload).forEach(key => {
        state[key] = action.payload[key];
      });

      // Object.keys(action.payload)
      //   .sort()
      //   .forEach(k => console.log(k));
    }
  }
});

export const CopyActions = CopySlice.actions;
export const CopyReducer = CopySlice.reducer;
