import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import StudyBox from "../components/StudyBox";
import StudyParticipateEligibility from "../components/StudyParticipateEligibility";
import StudyParticipateRegister from "../components/StudyParticipateRegister";

function PageStudyParticipate() {
  const { personalDataQuestions, participatingSurveys } = useSelector(store => store.user);
  const [page, setPage] = useState(1);
  const { studyid } = useParams();

  const { studies } = useSelector(state => state.app);
  const { privateStudies } = useSelector(state => state.user);
  const study = studies.find(s => s.id == studyid) || privateStudies.find(s => s.id == studyid);
  const participating = participatingSurveys.find(s => s.id == studyid);
  const navigate = useNavigate();
  const handlePageComplete = () => setPage(page + 1);
  const handleRegistered = () => navigate(`/study/${study.id}/user/notifications`, { state: { confirm: true } });

  useEffect(() => {
    if (study && participating) navigate(`/study/${study.id}/user/schedule`, { replace: true });
  }, [study]);
  if (!study) return <></>;

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        <StudyBox study={study} />
        {page === 1 && <StudyParticipateEligibility onComplete={handlePageComplete} study={study} pdqs={personalDataQuestions} />}
        {page === 2 && <StudyParticipateRegister study={study} onComplete={handleRegistered} />}
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageStudyParticipate;
