import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StudyBox from "../components/StudyBox";
import { AppActions } from "../store/AppSlice";
import { Plugins } from "@capacitor/core";
const { LocalNotifications } = Plugins;
const PERIODS = ["morning", "lunch", "evening", "night"];

function PageStudyUserNotifications() {
  const { studyid } = useParams();
  const { copy } = useSelector(state => state);
  const { participatingSurveys } = useSelector(state => state.user);
  const study = participatingSurveys.find(s => s.id == studyid);
  const [times, setTimes] = useState({});
  const [errors, setErrors] = useState({});
  const [ready, setReady] = useState(false);
  const [current, setCurrent] = useState([]);
  const [pendingNotifications, setPendingNotifications] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const form = useRef();

  const fetchCurrentNotifications = async () => {
    if (!LocalNotifications) {
      setReady(true);
      dispatch(AppActions.setAppState("idle"));
      return;
    }
    dispatch(AppActions.setAppState("processing"));
    let pending = await LocalNotifications.getPending();
    let current = pending.notifications.filter(n => n.extra.study == studyid);
    if (current) {
      const invalid = current.filter(n => !study[`notifications_${n.extra.time}`]);
      if (invalid.length) {
        await LocalNotifications.cancel({ notifications: current });
        pending = await LocalNotifications.getPending();
        current = pending.notifications.filter(n => n.extra.study == studyid);
      }
      setTimes(
        PERIODS.reduce((obj, period) => {
          const entry = current.find(n => n.extra.time === period) || false;
          obj[period] = entry ? new Date(entry.schedule.at) : false;
          return obj;
        }, {})
      );
      setCurrent(current);
      setPendingNotifications(pending?.notifications || []);
    }

    setReady(true);
    dispatch(AppActions.setAppState("idle"));
  };

  useEffect(() => {
    fetchCurrentNotifications();
    if (study && !study.notificationsEnabled) navigate(`/study/${study.id}/user/schedule`);
  }, [study]);

  if (!ready || !study) return <></>;
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  const endDate = new Date(new Date(study.start_participation_at).getTime() + 60 * 60 * 24 * 1000 * study.survey_length);
  const complete = endDate.getTime() < currentDate.getTime();

  const nextPage = () => {
    navigate(location.state?.confirm ? `/study/${study.id}/user/confirmed` : `/study/${study.id}/user/schedule`);
  };

  const setNotifications = async () => {
    dispatch(AppActions.setAppState("processing"));

    if (LocalNotifications) {
      if (current?.length) await LocalNotifications.cancel({ notifications: current });

      const formData = new FormData(form.current);
      let id = pendingNotifications ? pendingNotifications.reduce((i, n) => Math.max(i, n.id + 1), 0) : 0;

      const notifications = [];
      for (let i = 0; i < study.survey_length; i++) {
        const date = new Date(study.start_participation_at);
        date.setDate(date.getDate() + i);
        PERIODS.forEach(period => {
          const value = formData.get(period);
          if (value) {
            notifications.push({
              id: id,
              title: study.name,
              body: study[`notifications_${period}`],
              extra: { study: study.id.toString(), time: period },
              schedule: { at: new Date(date.setHours(value.split(":")[0], value.split(":")[1])) }
            });
            id++;
          }
        }, {});
      }

      await LocalNotifications.schedule({ notifications });
    }

    dispatch(AppActions.setAppState("idle"));
    nextPage();
  };

  const setNoNotifications = async () => {
    if (LocalNotifications && current?.length) {
      dispatch(AppActions.setAppState("processing"));
      await LocalNotifications.cancel({ notifications: current });
      dispatch(AppActions.setAppState("idle"));
    }
    nextPage();
  };

  const setTime = e => {
    const t = { ...times };
    const split = e.target.value.split(":");
    t[e.target.name] = new Date(new Date().setHours(split[0], split[1]));
    setTimes(t);
  };

  const getTime = period => {
    const time = times[period];
    if (!time) {
      switch (period) {
        case "morning":
          return "07:00";
        case "lunch":
          return "12:30";
        case "evening":
          return "18:00";
        case "night":
          return "21:00";
      }
    }
    return `${time.getHours().toString().padStart(2, "0")}:${time.getMinutes().toString().padStart(2, "0")}`;
  };

  const getTimeMin = period => {
    switch (period) {
      case "morning":
        return "04:00";
      case "lunch":
        return "11:00";
      case "evening":
        return "17:00";
      case "night":
        return "20:00";
    }
  };
  const getTimeMax = period => {
    switch (period) {
      case "morning":
        return "10:59";
      case "lunch":
        return "16:59";
      case "evening":
        return "19:59";
      case "night":
        return "03:59";
    }
  };

  const getTimelabel = period => {
    switch (period) {
      case "morning":
      case "evening":
        return copy[`notifications_${period}`];
      case "lunch":
        return copy.notifications_lunchtime;
      case "night":
        return copy.notifications_nighttime;
    }
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        <StudyBox study={study} />
        <div className="contentBox p15">
          <h3 className="secTitleUnder">{study.notifications_title}</h3>
          <p>{current.length ? study.notifications_update_text : study.notifications_text}</p>
        </div>
        <form ref={form} action="">
          <div className="remindBox">
            {!complete &&
              PERIODS.reduce((arr, period, i) => {
                if (study[`notifications_${period}`]) {
                  arr.push(
                    <div key={i}>
                      <div className="remoinCol">
                        <div className="remindText">
                          <h3>{getTimelabel(period)}</h3>
                          <br />
                          <p> {study[`notifications_${period}`]}</p>
                        </div>
                        <input
                          name={period}
                          className="timeSelect"
                          type="time"
                          value={getTime(period)}
                          min={getTimeMin(period)}
                          max={getTimeMax(period)}
                          onChange={setTime}
                        />
                      </div>
                      {errors[period] && (
                        <p style={{ width: "100%", textAlign: "left" }} className="app-error">
                          {errors[period]}
                        </p>
                      )}
                    </div>
                  );
                }
                return arr;
              }, [])}
          </div>
          <br />
        </form>
        {!complete && (
          <div className="dualBtn mb15">
            <a className="nextBtn" onClick={setNotifications}>
              {copy.set_notifications_button}
            </a>
            <a className="nextBtn" onClick={setNoNotifications}>
              {copy.no_notifications_button}
            </a>
          </div>
        )}
        {complete && (
          <div className="dualBtn mb15">
            <a className="nextBtn" onClick={setNotifications}>
              {copy.view_schedule_button}
            </a>
          </div>
        )}
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageStudyUserNotifications;
