import { useRef } from "react";
import { useNavigate } from "react-router-dom";

function SearchStudiesBox(props) {
  const navigate = useNavigate();
  const searchField = useRef();
  const searchStudies = e => {
    e.preventDefault();
    navigate(`/searchstudies/${searchField.current.value}`);
  };

  const form = (
    <form action="#">
      <div className="searchInput">
        <input ref={searchField} type="search" name="searchStudies" id="searchStudies" style={{ width: "100%" }} />
        <button type="submit" onSubmit={e => e.preventDefault()} className="searchBtn" onClick={searchStudies}>
          <i className="fas fa-search"></i>
        </button>
      </div>
    </form>
  );

  return props.nowrap ? form : <div className="studies_form">{form}</div>;
  // <form action="#">
  //   <div className="searchInput">
  //     <input ref={searchField} type="search" name="searchStudies" id="searchStudies" />
  //     <button type="submit" onSubmit={e => e.preventDefault()} className="searchBtn" onClick={searchStudies}>
  //       <i className="fas fa-search"></i>
  //     </button>
  //   </div>
  // </form>
}
export default SearchStudiesBox;
