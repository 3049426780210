import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "../store/AppSlice";

function PageErrorPopup() {
  const { showErrors } = useSelector(state => state.app);
  const { copy } = useSelector(state => state);
  const dispatch = useDispatch();
  console.log("PageErrorPopup", showErrors);
  const getMessage = id => {
    switch (id) {
      case "internet":
        return copy.connection_error_internet || "no internet connection";
      case "timeout":
        return copy.connection_error_timeout || "connection timed out";
      default:
        return copy.connection_error_unknown || "an unknown error occured";
    }
  };

  return (
    <div className="error-overlay">
      <div className="whiteBox boRa mb10 questionBox text-center">
        <h2>{copy.connection_error_title || "Cannot connect to the Internet"}</h2>
        {/* {showErrors.map((e, i) => {
          return (
            <>
              <p className="fz20 mb15" key={i}>
                {getMessage("sdasd")}
              </p>
              <br />
            </>
          );
        })}
         */}
        <p className="fz20 mb15">
          {copy.connection_error_body ||
            "The app cannot connect to the internet to complete this task. Please check your connections press the ‘back’ button, and then try again."}
        </p>
        <div className="dualBtn">
          <a className="nextBtn" onClick={() => dispatch(AppActions.setShowErrors(false))}>
            {copy.connection_button || "back"}
          </a>
        </div>
      </div>
    </div>
  );
}

export default PageErrorPopup;
