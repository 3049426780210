import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ParticipateInSurvey } from "../api/AppApi";
import { AppActions } from "../store/AppSlice";
import { store } from "../store/store";
import { RefreshUserData } from "../store/UserSlice";
import ErrorList from "./ErrorList";

function StudyParticipateRegister(props) {
  const { study, onComplete } = props;
  const { copy } = useSelector(state => state);
  const [errors, setErrors] = useState(false);
  const dateSelect = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const validate = () => {
    const { userToken } = store.getState().user;
    const validationErrrors = [];
    if (!dateSelect.current.value) validationErrrors.push("please select a start date");
    if (validationErrrors.length) {
      setErrors(validationErrrors);
    } else {
      setErrors(false);
      dispatch(AppActions.setAppState("processing"));
      ParticipateInSurvey(userToken, study.id, { start_date: dateSelect.current.value, invite_code: study.invite_code }).then(response => {
        if (response.showErrors) {
          dispatch(AppActions.setShowErrors(response.showErrors));
          dispatch(AppActions.setAppState("idle"));
        } else if (response.loginRequired) {
          location.state = { onLogin: validate };
          dispatch(AppActions.setLoginOverlay(true));
          dispatch(AppActions.setAppState("idle"));
        } else if (response.errors === false) {
          setErrors(response.errors);
          dispatch(AppActions.setAppState("idle"));
        } else {
          dispatch(RefreshUserData()).then(a => onComplete());
        }
      });
    }
  };

  return (
    <>
      <div className="startDateCont p15">
        <h2 className="mb10">{copy.requirements_title}</h2>
        <p>{study.requirements_text}</p>
      </div>
      <div className="startDateCont p15">
        <h2 className="mb10">Your start date</h2>
        <div className="dateSelect">
          <input ref={dateSelect} type="date" />
        </div>
      </div>
      <br />
      <ErrorList errors={errors} />
      <div className="nex_btn">
        <a className="nextBtn" onClick={validate}>
          Next
        </a>
      </div>
    </>
  );
}

export default StudyParticipateRegister;
