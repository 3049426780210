import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PageForum from "../pages/PageForum";
import PageForumThreadReply from "../pages/PageForumThreadReply";
import PageForumThread from "../pages/PageForumThread";
import ProtectedRoutes from "./ProtectedRoutes";
import PageForumThreadNew from "../pages/PageForumThreadNew";
import PageForumThreadEdit from "../pages/PageForumThreadEdit";

function RouteStudyForum() {
  return (
    <Routes>
      <Route index element={<PageForum />} />
      <Route path="/topic/:threadid" element={<PageForumThread />} />

      <Route element={<ProtectedRoutes />}>
        <Route path="/topic/:threadid/reply" element={<PageForumThreadReply />} />
        <Route path="/topic/edit" element={<PageForumThreadEdit />} />
        <Route path="/topic/new" element={<PageForumThreadNew />} />
      </Route>
    </Routes>
  );
}

export default RouteStudyForum;
