import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SortStudies } from "../api/Utils";
import SearchStudiesBox from "../components/SearchStudiesBox";
import StudyBox from "../components/StudyBox";
import triangle from "../img/arrow-down.png";

function PageStudies() {
  const navigate = useNavigate();
  const [order, setOrder] = useState("latest");
  const { studies = [] } = useSelector(state => state.app);
  const ordered = SortStudies([...studies], order);

  const handleChangeOrder = e => {
    e.preventDefault();
    setOrder(e.target.value);
  };

  return (
    <section className="screen">
      <div className="screen-header"></div>
      <div className="screen-body container">
        <section>
          <div className="searchResultBox searchResultBox2">
            <h2 className="toptile">All Studies</h2>
            <div className="selectPink">
              <select className="selectOpt" name="StudiesSelect" id="StudiesSelect" onChange={handleChangeOrder}>
                <option value="latest">Latest</option>
                <option value="alphanumeric">A-Z + 0-9</option>
                <option value="numericalpha">0-9 + A-Z</option>
              </select>
              <label htmlFor="">
                <img src={triangle} alt="triangle" />
              </label>
            </div>
            <br />
            {ordered.map(study => {
              return <StudyBox key={study.id} study={study} selectable />;
            })}

            <section style={{ marginBottom: 25 }}>
              <h2 className="toptile">Search</h2>
              <SearchStudiesBox nowrap={true} />
            </section>
          </div>{" "}
        </section>
        <section>
          <div className="text-center">
            <a className="nextBtn" onClick={() => navigate(-1)}>
              Back
            </a>
          </div>
        </section>
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageStudies;
