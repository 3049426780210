import React from "react";
import { useNavigate } from "react-router-dom";

function NavigationFooter() {
  const navigate = useNavigate();

  return (
    <footer className="FooterNavigation">
      <div className="container">
        <div className="footeNavigateCont">
          <div className="homeMenu">
            <a onClick={() => navigate("/")}>
              <i className="fas fa-home"></i>
            </a>
          </div>
          <div className="search_icon">
            <a className="searchTriger" onClick={() => navigate("/searchstudies")}>
              <i className="fas fa-search"></i>
            </a>
          </div>
          <div className="UserIcon">
            <a onClick={() => navigate("/account")}>
              <i className="far fa-user-circle"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default NavigationFooter;
