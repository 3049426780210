import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { AbandonSurvey, CompleteSurvey, ToDateText } from "../api/AppApi";
import ErrorList from "../components/ErrorList";
import StudyBox from "../components/StudyBox";
import { AppActions } from "../store/AppSlice";
import { store } from "../store/store";
import { RefreshUserData } from "../store/UserSlice";
function PageStudyUserSchedule() {
  const { studyid } = useParams();
  const { copy } = useSelector(state => state);
  const { participatingSurveys } = useSelector(state => state.user);
  const study = participatingSurveys.find(s => s.id == studyid);
  const [state, setState] = useState("study");
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!study || !study.start_participation_at) navigate(`/study/${studyid}/participate`, { replace: true });
  }, [study]);
  if (!study) return <></>;

  const restartStudy = () => {
    const { userToken } = store.getState().user;
    dispatch(AppActions.setAppState("processing"));
    AbandonSurvey(userToken, studyid).then(response => {
      if (response.showErrors) {
        dispatch(AppActions.setShowErrors(response.showErrors));
        dispatch(AppActions.setAppState("idle"));
      } else if (response.loginRequired) {
        location.state = { onLogin: restartStudy };
        dispatch(AppActions.setLoginOverlay(true));
        dispatch(AppActions.setAppState("idle"));
      } else if (response.errors) {
        setErrors(response.errors);
        dispatch(AppActions.setAppState("idle"));
      } else {
        dispatch(RefreshUserData()).then(() => {
          navigate(`/study/${studyid}/participate`);
          dispatch(AppActions.setAppState("idle"));
        });
      }
    });
  };

  const cancelRestartStudy = () => {
    setErrors(false);
    setState("study");
  };

  const renderActiveStudy = () => {
    const startDate = new Date(study.start_participation_at);
    const todaysDate = new Date(new Date().setHours(0, 0, 0, 0));
    const endDate = new Date(startDate.getTime() + 60 * 60 * 24 * 1000 * study.survey_length);
    const recordedDates = study.responses.map(r => new Date(r.date));

    const schedule = [];
    const required = [];
    for (let i = 0; i < study.survey_length; i++) {
      const date = new Date(startDate.getTime() + 60 * 60 * 24 * 1000 * i);
      if (date.getTime() > todaysDate.getTime()) schedule.push(date);
      else {
        const hasLogged = recordedDates.find(d => d.getTime() == date.getTime());
        if (!hasLogged) required.push(date);
      }
    }

    return (
      <>
        <div className="contentBox p15">
          <h2 className="mb0">{ToDateText(todaysDate)}</h2>
          <p className="mb15">{study.requirements_text || "study.requirements_text not found"}</p>
          <p className="mb15">
            {startDate > todaysDate
              ? copy.no_activity_to_log_needed_title
              : required.length && required[0].getTime() <= todaysDate.getTime()
              ? copy.activity_to_log_needed_title
              : copy.activity_logged_title}
          </p>
          {required.length > 0 && required[0].getTime() <= todaysDate.getTime() && (
            <div className="nex_btn">
              <a className="nextBtn" onClick={() => navigate(`/study/${studyid}/user/logactivity`)}>
                {copy.log_activity_button}
              </a>
              <br />
            </div>
          )}
        </div>
        <div className="contentBox p15">
          {schedule.map((entry, i) => {
            return (
              <div key={i} className="activityDate">
                <h2 className="mb0">{ToDateText(entry)}</h2>
                <p className="mb15">{study.requirements_text || "study.requirements_text not found"}</p>
              </div>
            );
          })}
        </div>
        <br />
        <div className="dualBtn mb15">
          {study.notificationsEnabled && endDate.getTime() > todaysDate.getTime() && (
            <a className="nextBtn" onClick={() => navigate(`/study/${study.id}/user/notifications`)}>
              Notification Settings
            </a>
          )}
          {study.responses.length > 0 && (
            <a className="nextBtn" onClick={() => navigate(`/study/${studyid}/user/progress`)}>
              {copy.view_progress_button}
            </a>
          )}
        </div>
      </>
    );
  };

  const renderCompleteStudy = () => {
    return (
      <>
        <div className="contentBox p15">
          <h2 className="mb0">{copy.completed_study_view_title || "{copy not found : completed_study_view_title}"}</h2>
          <p className="mb15">{copy.completed_study_view_body || "{copy not found : completed_study_view_body}"}</p>
        </div>
        <div className="dualBtn mb15">
          <a className="nextBtn" onClick={() => setState("restart")}>
            {copy.restart_study_button || "{copy not found : restart_study_button}"}
          </a>
          <a className="nextBtn" onClick={() => navigate(`/study/${studyid}/user/progress`)}>
            {copy.view_progress_button}
          </a>
        </div>
      </>
    );
  };

  const renderRestartSurveyConfirm = () => {
    return (
      <>
        <div className="contentBox p15">
          <h2 className="mb0">{copy.restart_study_warning_title || "{copy not found : restart_study_warning_title}"}</h2>
          <p className="mb15">{copy.restart_study_warning_body || "{copy not found : restart_study_warning_body}"}</p>
        </div>
        <div className="dualBtn mb15">
          {errors && <ErrorList errors={errors} />}
          <a className="nextBtn" onClick={restartStudy}>
            {copy.restart_study_ok_button || "{copy not found : restart_study_ok_button}"}
          </a>
          <a className="nextBtn" onClick={cancelRestartStudy}>
            {copy.restart_study_cancel_button || "{copy not found : restart_study_cancel_button}"}
          </a>
        </div>
      </>
    );
  };

  const renderPage = () => {
    switch (state) {
      case "study":
        return study.surveyComplete ? renderCompleteStudy() : renderActiveStudy();
      case "restart":
        return renderRestartSurveyConfirm(0);
    }
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body ">
        <StudyBox study={study} />
        {renderPage()}
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageStudyUserSchedule;
