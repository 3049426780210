import { Network } from "@capacitor/network";

const API_BASE = "https://dev-experime.newlogic.uk/";

export const API = (api, params, method = "GET", token) => {
  return new Promise((resolve, reject) => {
    new Promise(async () => {
      const networkstatus = await Network.getStatus();

      if (!networkstatus.connected) return resolve({ errors: ["no internet connection"], noInternet: true, showErrors: ["internet"] });

      const response = new XMLHttpRequest();
      response.open(method, API_BASE + api);
      response.timeout = 10000; // time in milliseconds
      if (token) response.setRequestHeader("authorization", "Bearer " + token);
      response.setRequestHeader("Content-Type", "application/json");
      response.onload = e => {
        if (response.status === 404) return resolve({ errors: ["an error occured"], showErrors: ["unknown"] });
        try {
          let results = false;
          results = JSON.parse(response.response);
          resolve(results);
        } catch (e) {
          resolve({ errors: ["an error occured"] });
        }
      };
      response.onerror = e => resolve({ errors: ["an error occured"], showErrors: ["unknown"] });
      response.ontimeout = e => resolve({ errors: ["connection timed out"], showErrors: ["timeout"] });
      params = params ? JSON.stringify(params) : params;
      response.send(params);
    }).catch(e => resolve({ errors: ["an error occured"], showErrors: ["unknown"] }));
  });
};

const APIUserCheck = (api, params, method = "GET", token) => {
  return new Promise(async resolve => {
    const user = await GetUser(token);
    if (!user || (user.errors && !user.noInternet)) {
      if (user.noInternet) resolve(user);
      else resolve({ errors: ["login required"], loginRequired: true });
    } else {
      const response = await API(api, params, method, token);
      resolve(response);
    }
  });
};

export const Login = async (email, password) => {
  // -- check
  return await API("api/login", { email, password }, "POST");
};

export const ChangePassword = async (passCurrent, passNew, token) => {
  // -- check
  return APIUserCheck(
    "api/change-password",
    {
      password: passCurrent,
      new_password: passNew
    },
    "POST",
    token
  );
};

export const GetUser = token => {
  return API("api/get-user", null, "GET", token);
};

export const UpdateUserProfile = (data, token) => {
  //--check
  return APIUserCheck("api/update-profile", { ...data }, "POST", token);
};

export const GetAppSettings = () => {
  //--check
  return API("api/get-app-settings");
};

export const Register = ({ name, email, password }) => {
  //-- check
  return API("api/register", { name, email, password }, "POST");
};

export const ForgotPassword = email => {
  //-- check
  return API("api/forgot-password", { email }, "POST");
};

export const SetPersonalData = (data, token) => {
  // -- check
  return APIUserCheck("api/set-personal-data", { data }, "POST", token);
};

export const ParticipateInSurvey = (token, surveyid, { start_date, invite_code = false }) => {
  //-- check
  return APIUserCheck("api/participate-in-survey", { survey: surveyid, start_date, invite_code }, "POST", token);
};

export const SubmitSurveyResponse = (token, surveyid, date, data) => {
  // -- check
  return APIUserCheck(
    "api/submit-survey-response",
    {
      survey: surveyid,
      date,
      data
    },
    "POST",
    token
  );
};

export const AbandonSurvey = (token, surveyid) => {
  // --check
  return APIUserCheck(
    "api/abandon-survey",
    {
      survey: surveyid
    },
    "POST",
    token
  );
};
// export const CompleteSurvey = (token, surveyid) => {
//   //--
//   return APIUserCheck(
//     "api/complete-survey",
//     {
//       survey: surveyid
//     },
//     "POST",
//     token
//   );
// };

export const GetPrivateStudy = (invite_code, token) => {
  // -- check
  return APIUserCheck("api/get-private-survey", { invite_code }, "POST", token);
};

// =============================================================================================
// GET DATA
// =============================================================================================
export const GetPublicSurveys = token => {
  return API("api/get-public-surveys", null, "GET", token);
};

export const GetPersonalDataQuestions = token => {
  return API("api/get-personal-data-questions", null, "GET", token);
};

export const GetParticipatingSurveys = token => {
  return API("api/get-participating-surveys", null, "GET", token);
};

export const GetPersonalData = token => {
  return API("api/get-personal-data", null, "GET", token);
};

export const GetForumThread = threadid => {
  // --
  return API(`forum/api/thread/${threadid}`);
};

export const GetForumThreads = categoryid => {
  // --
  return API(`forum/api/category/${categoryid}/thread`);
};

export const GetForumThreadPosts = (threadid, page) => {
  // --
  if (page) return API(`forum/api/thread/${threadid}/posts?page=${page}`);
  return API(`forum/api/thread/${threadid}/posts`);
};

export const PostForumTopic = (categoryid, { title, content = " " }, token) => {
  //--
  return APIUserCheck(
    `forum/api/category/${categoryid}/thread`,
    {
      title,
      content
    },
    "POST",
    token
  );
};

export const PostForumResponse = (threadid, comment, token) => {
  //--
  return APIUserCheck(
    `forum/api/thread/${threadid}/posts`,
    {
      id: threadid,
      content: comment
    },
    "POST",
    token
  );
};

export const EditForumResponse = (postid, comment, token) => {
  // --
  return APIUserCheck(
    `forum/api/post/${postid}`,
    {
      id: postid,
      content: comment
    },
    "PATCH",
    token
  );
};

export const DeleteForumThread = (threadid, token) => {
  // --
  return APIUserCheck(`forum/api/thread/${threadid}`, null, "DELETE", token);
};

export const DeleteForumResponse = (postid, token) => {
  // --
  return APIUserCheck(`forum/api/post/${postid}`, null, "DELETE", token);
};

// =============================================================================================
// UTILS
// =============================================================================================
export const ValidateEmail = email => {
  const res =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
};

export const ValidatePass = pass => {
  if (pass.length < 8) return false;
  if (!/[A-Z]/g.test(pass)) return false;
  if (!/[a-z]/g.test(pass)) return false;
  if (!/[0-9]/g.test(pass)) return false;
  if (!/[` !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pass)) return false;
  return true;
};

export const ToDateText = date => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const yearNow = date.getFullYear();
  const monthNow = months[date.getMonth()];
  const dayNow = date.getDate();
  let daySuffix; /*from   w  w w . j a  v  a 2 s.  c  o m*/

  switch (dayNow) {
    case 1:
    case 21:
    case 31:
      daySuffix = "st";
      break;
    case 2:
    case 22:
      daySuffix = "nd";
      break;
    case 3:
    case 23:
      daySuffix = "rd";
      break;
    default:
      daySuffix = "th";
      break;
  }

  return `${dayNow}${daySuffix} ${monthNow} ${yearNow}`;
};

export const ToApiDateString = date => {
  const yearNow = date.getFullYear();
  const monthNow = date.getMonth() + 1;
  const dayNow = date.getDate();
  return `${yearNow}-${monthNow < 10 ? "0" + monthNow : monthNow}-${dayNow < 10 ? "0" + dayNow : dayNow}`;
};

// derive string key
async function deriveKey(password) {
  const algo = {
    name: "PBKDF2",
    hash: "SHA-256",
    salt: new TextEncoder().encode("a-unique-salt"),
    iterations: 1000
  };
  return crypto.subtle.deriveKey(
    algo,
    await crypto.subtle.importKey(
      "raw",
      new TextEncoder().encode(password),
      {
        name: algo.name
      },
      false,
      ["deriveKey"]
    ),
    {
      name: "AES-GCM",
      length: 256
    },
    false,
    ["encrypt", "decrypt"]
  );
}

const encrypt = async (text, password) => {
  const algo = {
    name: "AES-GCM",
    length: 256,
    iv: crypto.getRandomValues(new Uint8Array(12))
  };
  return {
    cipherText: await crypto.subtle.encrypt(algo, await deriveKey(password), new TextEncoder().encode(text)),
    iv: algo.iv
  };
};

const decrypt = async (encrypted, password) => {
  const algo = {
    name: "AES-GCM",
    length: 256,
    iv: encrypted.iv
  };
  return new TextDecoder().decode(await crypto.subtle.decrypt(algo, await deriveKey(password), encrypted.cipherText));
};

export const StoreUser = async (email, pass, token) => {
  const user = await encrypt(token, "user");
  localStorage.setItem("experime", JSON.stringify({ iv: getArrayBufferString(user.iv), cipherText: getArrayBufferString(user.cipherText) }));
  return true;
};

export const GetStoredUser = async () => {
  const key = localStorage.getItem("experime");
  const data = key ? JSON.parse(key) : false;
  if (data) {
    data.cipherText = parseArrayBufferString(data.cipherText);
    data.iv = parseArrayBufferString(data.iv);
    const decrypted = await decrypt(data, "user");
    return decrypted;
    if (decrypted) {
      const arr = JSON.parse(decrypted);
      return { email: arr[0], pass: arr[1] };
    }
  }
  return false;
};

export const ClearStoredUser = () => {
  localStorage.setItem("experime", null);
};

function getArrayBufferString(arrayBuffer) {
  return new Uint8Array(arrayBuffer).toString();
}

// returns ArrayBuffer
function parseArrayBufferString(string) {
  return new Uint8Array(string.split(",")).buffer;
}
