import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AccountCreate from "../components/AccountCreate";
import AccountForgotPass from "../components/AccountForgotPass";
import AccountLogin from "../components/AccountLogin";
import ErrorList from "../components/ErrorList";

function PageLogin(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedIn } = useSelector(state => state.user);
  const [state, setState] = useState("login");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    // if (loggedIn) {
    //   if (location.state?.from) navigate(location.state.from, { replace: true, ...location.state.from });
    //   else navigate("/", { replace: true });
    // }
  }, [loggedIn]);

  const handleLoginComplete = () => {
    if (!props.noRedirect) {
      if (location.state?.from) navigate(location.state.from, { replace: true, ...location.state.from });
      else navigate("/", { replace: true });
    }

    if (location.state?.onLogin) location.state?.onLogin();
  };

  const handleAccountCreateComplete = evt => {
    if (evt?.errors) setErrors(evt.errors);
    setState("login");
  };

  let page = <></>;
  switch (state) {
    case "login":
      page = (
        <>
          <AccountLogin onComplete={handleLoginComplete} onCreate={() => setState("create")} onForgotPass={() => setState("forgotpass")} />
          {errors && <ErrorList errors={errors} />}
        </>
      );
      break;
    case "create":
      page = <AccountCreate onComplete={handleAccountCreateComplete} onCancel={() => setState("login")} />;
      break;
    case "forgotpass":
      page = <AccountForgotPass onComplete={() => setState("login")} />;
      break;
  }

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">{page}</div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageLogin;
