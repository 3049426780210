import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SubmitSurveyResponse, ToApiDateString, ToDateText } from "../api/AppApi";
import ErrorList from "../components/ErrorList";
import QuestionForm from "../components/QuestionForm";
import StudyBox from "../components/StudyBox";
import { AppActions } from "../store/AppSlice";
import { store } from "../store/store";
import { RefreshUserData } from "../store/UserSlice";

function PageStudyUserLogActivity() {
  const { studyid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { participatingSurveys } = useSelector(state => state.user);
  const { copy } = useSelector(state => state);
  const [logged, setLogged] = useState(false);
  const [state, setState] = useState("idle");
  const [errors, setErrors] = useState(false);
  const location = useLocation();
  const study = participatingSurveys.find(s => s.id == studyid);
  const startDate = new Date(study.start_participation_at);
  const todaysDate = new Date(new Date().setHours(0, 0, 0, 0));
  const recordedDates = study.responses.map(r => new Date(r.date));

  const requiredDates = [];
  for (let i = 0; i < study.survey_length; i++) {
    const date = new Date(startDate.getTime() + 60 * 60 * 24 * 1000 * i);
    if (date <= todaysDate) {
      const hasLogged = recordedDates.find(d => date.getTime() == d.getTime()) ? true : false;
      if (!hasLogged) requiredDates.push(date);
    }
  }

  useEffect(() => {
    if (!study) navigate("/");
    if (requiredDates.length == 0) {
      navigate(`/study/${studyid}/user/schedule`);
    }
  }, [state]);

  if (!study) return <></>;

  const handleActivityLogged = () => {
    setIdle();
  };

  const setIdle = () => {
    setState("idle");
    dispatch(AppActions.setAppState("idle"));
  };

  const onFormSubmit = formdata => {
    const { userToken } = store.getState().user;
    dispatch(AppActions.setAppState("processing"));
    setState("processing");
    SubmitSurveyResponse(userToken, study.id, ToApiDateString(requiredDates[0]), formdata).then(async response => {
      if (response.showErrors) {
        dispatch(AppActions.setShowErrors(response.showErrors));
        setIdle();
      } else if (response.loginRequired) {
        location.state = { onLogin: () => onFormSubmit(formdata) };
        dispatch(AppActions.setLoginOverlay(true));
        setIdle();
      } else if (response.errors) {
        setErrors(response.errors);
        setIdle();
      } else {
        setLogged(true);
        await dispatch(RefreshUserData());
        if (requiredDates[0].length === 1) {
          navigate(`/study/${studyid}/user/schedule`, { replace: true });
        }
        setIdle();
      }
    });
  };

  if (state === "processing") return <></>;

  return (
    <section className="screen">
      <div className="screen-body">
        <StudyBox study={study} />
        {requiredDates.length > 0 && (
          <>
            <h2 className="mb15">
              {!logged ? copy.log_activity_for_title : copy.thanks_and_now_log_activity_for_title} {ToDateText(requiredDates[0])}
            </h2>
            <p className="mb15">{copy.log_activity_for_body}</p>
            <QuestionForm questions={study.questions} onSubmit={onFormSubmit} />
            {errors && <ErrorList errors={errors} />}
          </>
        )}
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageStudyUserLogActivity;
