import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ClearStoredUser,
  GetParticipatingSurveys,
  GetPersonalData,
  GetPersonalDataQuestions,
  GetPrivateStudy,
  GetPublicSurveys,
  GetUser,
  Login,
  StoreUser
} from "../api/AppApi";
import { AppActions } from "./AppSlice";

const initialState = {
  userName: false,
  userToken: false,
  userEmail: false,
  loggedIn: false,
  id: 0,
  forumAdmin: false,
  participatingSurveys: [],
  personalDataQuestions: [],
  personalDataAnswers: {},
  privateStudies: []
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setState(state, action) {
      state.state = action.payload;
    },
    setUser(state, action) {
      state.userName = action.payload.userName;
      state.userToken = action.payload.userToken;
      state.userEmail = action.payload.userEmail;
      state.id = action.payload.id;
      state.forumAdmin = action.payload.forumAdmin;
      state.loggedIn = state.userName ? true : false;
    },
    clearUser(state) {
      state.userName = false;
      state.userToken = false;
      state.userEmail = false;
      state.loggedIn = false;
      state.status = "idle";
    },
    addPrivateStudy(state, action) {
      const filtered = state.privateStudies.filter(s => s.id != action.payload.id);
      filtered.push({ is_private: true, ...action.payload });
      state.privateStudies = filtered;
    }
  },
  extraReducers: builder => {
    builder.addCase(RefreshUserData.fulfilled, (state, action) => {
      state.personalDataQuestions = action.payload.personalDataQuestions;
      state.participatingSurveys = action.payload.participatingSurveys;
      state.personalDataAnswers = action.payload.personalDataAnswers;
      state.privateStudies = action.payload.privateStudies;
    });
  }
});

export const UserActions = UserSlice.actions;
export const UserReducer = UserSlice.reducer;

export const RefreshUserData = createAsyncThunk("user/update", async (data, { dispatch, getState }) => {
  dispatch(AppActions.setAppState("processing"));
  const state = getState();
  const surveys = await GetPublicSurveys(state.user.userToken);
  const personalDataQuestions = await GetPersonalDataQuestions(state.user.userToken);
  const personalDataAnswers = await GetPersonalData(state.user.userToken);
  const participatingSurveysApi = await GetParticipatingSurveys(state.user.userToken);

  const privateStudies = [];
  for (let i = 0; i < state.user.privateStudies.length; i++) {
    const study = state.user.privateStudies[i];
    if (study.invite_code) {
      const updatedData = await GetPrivateStudy(study.invite_code, state.user.userToken);
      privateStudies.push({ invite_code: study.invite_code, is_private: true, ...updatedData.data });
    } else privateStudies.push(study);
  }

  const participatingSurveys = participatingSurveysApi.data.map(s => parseParticipatingSurvey(s));

  dispatch(AppActions.setAppState("idle"));
  return {
    personalDataQuestions: personalDataQuestions.errors ? [] : personalDataQuestions.data,
    participatingSurveys: participatingSurveys.errors ? [] : participatingSurveys,
    personalDataAnswers: personalDataAnswers.errors ? [] : personalDataAnswers,
    privateStudies: privateStudies.errors ? [] : privateStudies,
    surveys: surveys.errors ? [] : surveys.data
  };
});

export const LoginUser = createAsyncThunk("user/login", async (data, { dispatch, getState }) => {
  dispatch(AppActions.setAppState("processing"));
  const loginResponse = await Login(data.email, data.pass);
  let result = false;
  if (loginResponse.success) {
    await dispatch(
      UserActions.setUser({
        id: loginResponse.id,
        forumAdmin: loginResponse.forumAdmin,
        userName: loginResponse.name,
        userEmail: loginResponse.email,
        userToken: loginResponse.token
      })
    );
    await dispatch(RefreshUserData());
    await StoreUser(data.email, data.pass, loginResponse.token);
    result = { success: true };
  } else if (loginResponse.showErrors) {
    dispatch(AppActions.setShowErrors(loginResponse.showErrors));
    result = { success: false };
  } else if (loginResponse.errors) {
    result = { success: false, errors: loginResponse.message };
  } else {
    dispatch(AppActions.setShowErrors(["unknown"]));
    result = { success: false };
  }
  dispatch(AppActions.setAppState("idle"));
  return result;
});

export const LogoutUser = createAsyncThunk("user/logout", async (data, { dispatch, getState }) => {
  dispatch(AppActions.setAppState("processing"));
  dispatch(UserActions.clearUser());
  ClearStoredUser();
  dispatch(AppActions.setAppState("idle"));
  return { success: true };
});

const parseParticipatingSurvey = survey => {
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const surveyStart = new Date(survey.start_participation_at);
  const surveyEnd = new Date(surveyStart.getTime() + 60 * 60 * 24 * 1000 * survey.survey_length);
  return {
    ...survey,
    startDate: surveyStart.getTime(),
    endDate: surveyEnd.getTime(),
    surveyComplete: surveyEnd.getTime() < today.getTime(),
    notificationsEnabled:
      survey.notifications_morning || survey.notifications_lunch || survey.notifications_evening || survey.notifications_night ? true : false
  };

  // const surveyRecorded = survey.responses.map(r => new Date(r.date));

  // const surveySchedule = [];
  // const surveyLogsRequired = [];
  // for (let i = 0; i < survey.survey_length; i++) {
  //   const date = new Date(surveyStart.getTime() + 60 * 60 * 24 * 1000 * i);
  //   if (date.getTime() > today.getTime()) surveySchedule.push(date);
  //   else {
  //     const hasLogged = surveyRecorded.find(d => d.getTime() == date.getTime());
  //     if (!hasLogged) surveyLogsRequired.push(date);
  //   }
  // }
};
