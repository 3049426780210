import React from "react";

import logo from "../img/loading_head.png";
import logospinner from "../img/loading_spinner.png";

function LoadingScreen() {
  return (
    <section className="loadScreenContainer">
      <div className="container">
        <div className="loadScreenContent">
          <img className="loadingScreenHead" src={logo} alt="Intro Screen" />
          <img className="loadingScreenSpinner" src={logospinner} alt="Intro Screen" />
        </div>
      </div>
    </section>
  );
}

export default LoadingScreen;
