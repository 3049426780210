import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangePassword, ValidatePass } from "../api/AppApi";
import ErrorList from "../components/ErrorList";
import PasswordField from "../components/PasswordField";
import { AppActions } from "../store/AppSlice";
import { store } from "../store/store";

function PageAccountSettings() {
  const navigate = useNavigate();
  const { copy } = useSelector(state => state);
  const { user } = useSelector(state => state);
  const dispatch = useDispatch();
  const form = useRef();
  const [errors, setErrors] = useState(false);
  const [errorCurrent, setErrorCurrent] = useState(false);
  const [errorNew, setErrorNew] = useState(false);
  const [errorRepeat, setErrorRepeat] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const location = useLocation();

  const validate = async e => {
    e.preventDefault();
    const { userToken } = store.getState().user;
    dispatch(AppActions.setAppState("processing"));
    const data = {};
    const formData = new FormData(form.current);
    const currentPass = formData.get("password");
    const newPass = formData.get("new_password");
    const repeatPass = formData.get("repeat_password");
    let errCurr, errNew, errRpt;
    if (!currentPass) errCurr = "please enter current password";
    if (!newPass) errNew = "please enter new password";
    if (!repeatPass) errRpt = "please repeat new password";
    if (newPass !== repeatPass) errRpt = "passwords do not match";
    if (errCurr) setErrorCurrent(errCurr);
    if (errNew) setErrorNew(errNew);
    if (errRpt) setErrorRepeat(errRpt);
    if (!errCurr && !errNew && !errRpt) {
      const valid = ValidatePass(newPass);
      if (!valid) {
        setErrorNew(`invalid password\n ${copy.create_profile_create_rule_line_1}\n${copy.create_profile_rule_line_2}`);
        dispatch(AppActions.setAppState("idle"));
      } else {
        setErrorCurrent(false);
        setErrorNew(false);
        setErrorRepeat(false);
        const response = await ChangePassword(currentPass, newPass, userToken);
        if (response.showErrors) {
          dispatch(AppActions.setShowErrors(response.showErrors));
        } else if (response.loginRequired) {
          location.state = { onLogin: () => validate(e) };
          dispatch(AppActions.setLoginOverlay(true));
        } else if (response.success) {
          setErrors(false);
          setSubmitted(true);
        } else if (response.errors) {
          setErrors(response.errors);
        }
        dispatch(AppActions.setAppState("idle"));
      }
    }
  };

  const renderForm = () => {
    return (
      <>
        <h2 className="toptile">{copy.update_account_details_title || "copy.update_account_details_title"}</h2>
        <p className="fz20 mb15">{copy.update_account_details_body || "copy.update_account_details_body"}</p>
        {errors && <ErrorList errors={errors} />}
        <form ref={form} action="" className="mb15">
          <div className="whiteBox boRa mb10 questionBox text-center">
            <p className="fz20">{copy.update_account_current_pass || "copy.update_account_current_pass"}</p>
            <PasswordField id={"password"} name={"password"} />
            {errorCurrent && <ErrorList errors={[errorCurrent]} />}
            <br />
            <br />
            <p className="fz20">{copy.update_account_new_pass || "copy.update_account_new_pass"}</p>
            <PasswordField id={"new_password"} name={"new_password"} />
            {errorNew && <ErrorList errors={[errorNew]} />}
            <br />
            <br />
            <p className="fz20">{copy.update_account_new_pass_repeat || "copy.update_account_new_pass_repeat"}</p>
            <PasswordField id={"repeat_password"} name={"repeat_password"} />
            {errorRepeat && <ErrorList errors={[errorRepeat]} />}
          </div>

          <div className="dualBtn text-center">
            <button type="submit" className="nextBtn" onClick={validate}>
              {copy.submit_button_text}
            </button>
            <br />
          </div>
        </form>
      </>
    );
  };

  const renderSubmitted = () => {
    return (
      <>
        <h2 className="toptile">{copy.update_account_details_success_title || "copy.update_account_details_success_title"}</h2>
        <div className="dualBtn text-center">
          <button className="nextBtn" onClick={() => navigate("/account")}>
            {copy.update_account_details_success_button || "copy.update_account_details_success_button"}
          </button>
        </div>
      </>
    );
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">{submitted ? renderSubmitted() : renderForm()}</div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageAccountSettings;
