import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GetPublicSurveys, GetUser, UpdateUserProfile } from "../api/AppApi";
import ErrorList from "../components/ErrorList";
import Question from "../components/Question";
import StudyBox from "../components/StudyBox";
import { AppActions } from "../store/AppSlice";
import { store } from "../store/store";
import { LogoutUser, RefreshUserData, UserActions } from "../store/UserSlice";

function PageAccountEdit() {
  const navigate = useNavigate();
  const { copy } = useSelector(state => state);
  const { user } = useSelector(state => state);
  const { userName } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const form = useRef();
  const [errors, setErrors] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const location = useLocation();

  const validate = async e => {
    e.preventDefault();
    const { userToken } = store.getState().user;
    dispatch(AppActions.setAppState("processing"));
    const data = {};
    const formData = new FormData(form.current);
    const name = formData.get("name");
    const bio = formData.get("biography");
    const avatar = formData.get("profile");
    if (avatar !== "false") data.avatar = avatar;
    if (bio) data.bio = bio;
    if (name) data.name = name;
    if (avatar) data.avatar = avatar;
    const response = await UpdateUserProfile(data, userToken);
    if (response.showErrors) {
      dispatch(AppActions.setShowErrors(response.showErrors));
    } else if (response.loginRequired) {
      location.state = { onLogin: () => validate(e) };
      dispatch(AppActions.setLoginOverlay(true));
    } else if (response.success) {
      await dispatch(RefreshUserData());
      const user = await GetUser(userToken);
      if (user.success) {
        dispatch(
          UserActions.setUser({
            id: user.id,
            forumAdmin: user.forumAdmin,
            userName: user.name,
            userEmail: user.email,
            userToken: userToken
          })
        );
      }
      const surveys = await GetPublicSurveys();
      if (!surveys.errors) dispatch(AppActions.setPublicSurveys(surveys.data));
      setErrors(false);
      setSubmitted(true);
      dispatch(AppActions.setAppState("idle"));
    } else if (response.errors) {
      setErrors(response.errors);
      dispatch(AppActions.setAppState("idle"));
    }
  };

  const renderForm = () => {
    return (
      <>
        <h2 className="toptile">{copy.update_personal_details_title || "copy.update_personal_details_title"}</h2>
        <p className="fz20">{copy.update_personal_details_body || "copy.update_personal_details_body"}</p>
        <form ref={form} action="" className="mb15">
          <Question data={{ type: "input", id: "name", name: copy.change_your_name_title || "copy.change_your_name_title", value: userName || "" }} />
          {/* <Question
            data={{
              type: "text",
              id: "biography",
              name: copy.change_your_bio_title || "copy.change_your_bio_title",
              rows: 8,
              value: user.userBio || ""
            }}
          /> */}
          {/* <Question
            data={{
              type: "image",
              id: "profile",
              name: copy.change_your_profile_image_title || "copy.change_your_profile_image_title",
              label: copy.add_new_image_button_text || "copy.add_new_image_button_text"
            }}
          /> */}
          <div className="dualBtn text-center">
            {errors && <ErrorList errors={errors} />}
            <button type="submit" className="nextBtn" onClick={validate}>
              {copy.update_profile_button || "copy.update_profile_button"}
            </button>
            <br />
          </div>
        </form>
      </>
    );
  };

  const renderSubmitted = () => {
    return (
      <>
        <h2 className="toptile">profile updated</h2>
        <div className="dualBtn text-center">
          <button className="nextBtn" onClick={() => navigate("/account")}>
            next
          </button>
        </div>
      </>
    );
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">{submitted ? renderSubmitted() : renderForm()}</div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageAccountEdit;

const convertToBinary = base64 => {
  return new Promise(resolve => {
    fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        // now upload it
        // fetch(api, { method: "POST", body: blob });
        resolve(blob);
      });
  });
};

const convertDataURItoFile = (dataURI, fileName) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  // use `File` constructor here
  var blob = new File([ia], fileName, { type: mimeString, lastModifiedDate: new Date() });

  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  // blob.lastModifiedDate = new Date();
  // blob.name = fileName;
  //Cast to a File() type
  return blob;
};

// const convertToFile = base64 => {
//   return new Promise(resolve => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       console.log(`data URI: ${reader.result}`);
//       document.querySelector("iframe").src = reader.result;
//       resolve(reader.result);
//     };

//     reader.readAsDataURL(convertDataURItoFile(datauri, filename));
//   });
// };
