import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorList from "./ErrorList";
import EyeSlash from "../img/eye-slash-regular.svg";
import Eye from "../img/eye-regular.svg";
import { ValidateEmail } from "../api/AppApi";
import { LoginUser } from "../store/UserSlice";

function AccountLogin(props) {
  const { onCreate, onForgotPass } = props;
  const [errors, setErrors] = useState(false);
  const emailField = useRef();
  const passField = useRef();
  const [showpass, setShowPass] = useState(false);
  const dispatch = useDispatch();
  const { login_page_title, login_page_body, login_page_forgot_pass_link } = useSelector(state => state.copy);
  const login = e => {
    const emailValid = ValidateEmail(emailField.current.value);
    const passwordEntered = passField.current.value.length > 0;
    const validSubmition = emailValid && passwordEntered;

    if (validSubmition) {
      setErrors(false);
      dispatch(LoginUser({ email: emailField.current.value, pass: passField.current.value })).then(response => {
        if (response.payload.errors) setErrors(response.payload.errors);
        else if (props.onComplete) props.onComplete();
      });
    } else {
      const errors = [];
      if (!emailValid) errors.push("please input a valid email address");
      if (!passwordEntered) errors.push("please input a password");
      setErrors(errors);
    }
  };

  const toggleShowPassword = () => {
    setShowPass(showpass === false);
  };

  return (
    <>
      <h2 className="text-center toptile">{login_page_title}</h2>
      <br />
      <br />
      <div className="formContainer existLogin">
        <div className="inputCol fz18">{login_page_body}</div>
        <br />
        <div className="inputCol">
          <input ref={emailField} type="email" name="login-Email" id="login-Email" placeholder="Email Address" />
        </div>
        <div className="inputCol">
          <input ref={passField} type={showpass ? "text" : "password"} name="loginPass" id="loginPass" placeholder="Password" />
          <img src={showpass ? Eye : EyeSlash} alt="Eye" className="inputIcon eye" onClick={toggleShowPassword} />
        </div>
        <br />
        <ErrorList errors={errors} />
        <div className="formNext">
          <button className="next_button" onClick={login}>
            Submit
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className="loginHere text-center">
        <a onClick={onForgotPass}>{login_page_forgot_pass_link}</a>
        <br />
        <br />
      </div>
      <div className="loginHere text-center">
        <a onClick={onCreate}>create account</a>
      </div>
    </>
  );
}

export default AccountLogin;
