import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StudyBox from "../components/StudyBox";

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { ToDateText } from "../api/AppApi";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: false,

    title: {
      // display: true,
      // text: "numbers"
      // position: "left"
    }
  }
};

const style = {
  drawActiveElementsOnTop: true,
  backgroundColor: "rgba(255,255,255,255)",
  borderColor: "rgba(32,32,32,255)",
  backgroundColor: "rgba(85,135,41,255)",
  borderWidth: 2,
  radius: 5
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [5, 2, 6, 7, 9, 10, 6]
    },
    {
      label: "Dataset w",
      data: [1, 4, 6, 8, 7, 20]
    }
  ]
};

function PageStudyUserProgress() {
  const { studyid } = useParams();
  const { participatingSurveys } = useSelector(state => state.user);
  const study = participatingSurveys.find(s => s.id == studyid);
  const { copy } = useSelector(state => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (!study) navigate("/");
  }, []);

  if (!study) return <></>;
  const startDate = new Date(study.start_participation_at);

  const schedule = [];
  for (let i = 0; i < study.survey_length; i++) {
    const date = new Date();
    date.setDate(startDate.getDate() + i);
    schedule.push(date);
  }

  const charts = study.questions.reduce((results, question, i) => {
    if (question.type === "number" || question.type === "scale" || question.type == "boolean") {
      const responses = study.responses.reduce((arr, response) => {
        const value = response.data.find(d => d.id == question.id)?.value;
        if (value)
          arr.push(
            question.type == "boolean" || question.type == "boolean_details"
              ? value != "true" && value != "1"
                ? "true"
                : "false"
              : parseFloat(value)
          );
        else arr.push(false);
        return arr;
      }, []);
      const scales = {};
      if (question.type === "boolean") {
        scales.y = {
          border: { color: "rgba(0,0,0, 255)", width: 10 },
          grid: { display: false },
          type: "category",
          labels: [" ", "true", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", "false", " "]
        };
      } else {
        scales.yAxis = {
          border: { color: "rgba(0,0,0, 255)", width: 10 },
          grid: { display: false, color: "rgba(0,0,0, 255)" },
          min: question.minimum || 0,
          suggestedMax: question.type == "boolean" ? 1 : question.maximum || 100
        };
      }

      results.push(
        <div key={question.id} className="whiteBox mb15" style={{ borderRadius: 15, paddingRight: 30 }}>
          <Line
            options={{
              layout: {
                autoPadding: true
              },
              plugins: {
                legend: false,
                title: {
                  display: true,
                  text: question.chart_y_axis_title,
                  position: "left"
                }
              },
              scales: {
                x: {
                  tickColor: "rgba(0,0,0, 255)",
                  border: { color: "rgba(0,0,0, 255)", width: 10 },
                  lineWidth: 10,
                  grid: {
                    lineWidth: 1,
                    color: "rgba(0,0,0, 255)"
                  }
                },
                ...scales
              }
            }}
            data={{
              labels: schedule.map((s, i) => i + 1),
              datasets: [
                {
                  ...style,
                  label: question.chart_y_axis_title,
                  data: responses
                }
              ]
            }}
          />
          <p className="text-center">Days in Study</p>
        </div>
      );
    }
    return results;
  }, []);

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body ">
        <StudyBox study={study} />
        <h2 className="mb15">{copy.progress_page_title}</h2>
        <p className="mb15">{copy.progress_page_body}</p>
        {charts}
        <br />
        <br />
        <div className="dualBtn mb15">
          <a className="nextBtn" onClick={() => navigate(`/study/${studyid}/user/schedule`)}>
            {copy.view_schedule_button || "copy.view_schedule_button missing"}
          </a>
        </div>
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageStudyUserProgress;
