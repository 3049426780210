import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostForumResponse } from "../api/AppApi";
import ErrorList from "../components/ErrorList";
import StudyForumThreadItem from "../components/StudyForumThreadItem";
import { AppActions } from "../store/AppSlice";
import { store } from "../store/store";

function PageForumThreadReply() {
  const { studyid, threadid } = useParams();
  const { copy } = useSelector(state => state);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(false);
  const commentBox = useRef(false);
  const thread = useLocation().state?.thread;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const returnToForumThread = () => navigate(`/study/${studyid}/forum/topic/${threadid}`, { replace: true });

  useEffect(() => {
    if (!thread) returnToForumThread();
  }, []);

  if (!thread) return <></>;

  const validate = () => {
    const { userToken } = store.getState().user;
    if (commentBox.current.value.length) {
      dispatch(AppActions.setAppState("processing"));
      PostForumResponse(thread.id, commentBox.current.value, userToken).then(response => {
        if (response.showErrors) {
          dispatch(AppActions.setShowErrors(response.showErrors));
        } else if (response.loginRequired) {
          location.state = { thread, onLogin: validate };
          dispatch(AppActions.setLoginOverlay(true));
        } else if (response.errors) setErrors(response.errors);
        else setSubmitted(true);
        dispatch(AppActions.setAppState("idle"));
      });
    } else {
      setErrors(["please add a comment"]);
    }
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        {submitted ? (
          <>
            <div className="commentSuccessbox">
              <h2>{copy.thanks_for_comment_text}</h2>
            </div>
            <div className="dualBtn">
              <a className="nextBtn" onClick={returnToForumThread}>
                {copy.view_your_post_button}
              </a>
            </div>
          </>
        ) : (
          <>
            <h2 className="mb10">{copy.post_activity_title || "post_activity_title : not found"}</h2>
            <StudyForumThreadItem thread={thread} studyid={studyid} />
            <div className="comentpostBox mb15" style={{ marginTop: 15 }}>
              <form action="#">
                <h4>Post your comment here:</h4>
                <textarea ref={commentBox} name="ComentPostBox" id="ComentPostBox" placeholder="Tap to type"></textarea>
                {errors?.length > 0 && <ErrorList errors={errors} />}
              </form>
            </div>
            <div className="dualBtn">
              <a className="nextBtn" onClick={validate}>
                Submit
              </a>
              <a className="nextBtn" onClick={returnToForumThread}>
                Back
              </a>
            </div>
          </>
        )}
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageForumThreadReply;
