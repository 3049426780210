import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GetPrivateStudy } from "../api/AppApi";
import ErrorList from "../components/ErrorList";
import StudyBox from "../components/StudyBox";
import { AppActions } from "../store/AppSlice";
import { store } from "../store/store";
import { UserActions } from "../store/UserSlice";

function PageStudyAddPrivate() {
  const { copy } = useSelector(state => state);
  const [study, setStudy] = useState(false);
  const [errors, setErrors] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const codebox = useRef();
  const location = useLocation();

  const submitCode = () => {
    const { userToken } = store.getState().user;
    dispatch(AppActions.setAppState("processing"));
    GetPrivateStudy(codebox.current.value.trim(), userToken).then(response => {
      if (response.showErrors) {
        dispatch(AppActions.setShowErrors(response.showErrors));
      } else if (response.loginRequired) {
        location.state = { onLogin: submitCode };
        dispatch(AppActions.setLoginOverlay(true));
      } else if (response.success === false) {
        setErrors([response.message]);
      } else {
        dispatch(UserActions.addPrivateStudy({ is_private: true, invite_code: codebox.current.value.trim(), ...response.data }));
        setStudy(response.data);
        setErrors(false);
      }
      dispatch(AppActions.setAppState("idle"));
    });
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        {study ? (
          <>
            <h2 className="toptile whiteBoxTitle">
              {copy.private_study_title} <i className="far fa-eye-slash"></i>
            </h2>
            <p className="fz20">{copy.private_study_congratulations_copy}</p>
            <br />
            <StudyBox study={study} />
          </>
        ) : (
          <>
            <h2 className="toptile whiteBoxTitle">
              {copy.private_study_title} <i className="far fa-eye-slash"></i>
            </h2>
            <p className="fz20">{copy.private_study_body}</p>
            <br />
            <p className="fz20">{copy.enter_code_input}</p>
            <br />
            <div className="codeSubmit">
              <div className="inputCol enterCode">
                <input ref={codebox} type="text" placeholder="Enter code" />
              </div>
              <br />
              <ErrorList errors={errors} />
              <div className="text-center">
                <a className="nextBtn" onClick={submitCode}>
                  Submit
                </a>
              </div>
            </div>
          </>
        )}
        <br />
        <div className="text-center">
          <a className="nextBtn" onClick={() => navigate(`/`)}>
            {copy.back_to_studies_button}
          </a>
        </div>
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageStudyAddPrivate;
