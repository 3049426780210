import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SearchStudiesBox from "../components/SearchStudiesBox";
import StudyBox from "../components/StudyBox";

function PageSearch() {
  const navigate = useNavigate();

  const { params } = useParams();
  const { copy } = useSelector(state => state);

  const { studies } = useSelector(state => state.app);
  const searchTerms = params?.toLowerCase().trim();

  let results = studies.filter(study => study.name.toLowerCase().includes(searchTerms));
  studies.forEach(study => {
    if (study.user.toLowerCase().includes(searchTerms) && !results.includes(study)) {
      results.push(study);
    }
  });
  studies.forEach(study => {
    if (study.short_description.toLowerCase().includes(searchTerms) && !results.includes(study)) {
      results.push(study);
    }
  });

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        <section className="section_area">
          <div className="container">
            <h2 className="toptile">{copy.search_results_title}</h2>
            <SearchStudiesBox />

            <br />
            <br />
            <div className="searchResultBox">
              <p className="fz22 text-center">
                {results.length ? copy.search_results_subtitle + " " + searchTerms : searchTerms?.length ? copy.seacrch_no_results : ""}{" "}
                {!searchTerms?.length && "Please enter search parameters"}
              </p>
              <br />
              {results.map(study => (
                <StudyBox key={study.id} study={study} selectable />
              ))}
            </div>
            <br />
            <h2 className="text-center">
              <a href="#" className="cd" onClick={() => navigate("/studies")}>
                View all studies
              </a>
            </h2>
          </div>
        </section>
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageSearch;
