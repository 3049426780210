import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import comment from "../img/comment-regular.svg";
import ComentMenu from "../img/more.png";

function StudyForumThreadItem(props) {
  const { thread, studyid, onDelete, onEdit } = props;
  const { copy } = useSelector(state => state);
  const { loggedIn, id, forumAdmin } = useSelector(state => state.user);
  const timediff = Math.round((new Date().getTime() - new Date(thread.updated_at).getTime()) / (1000 * 3600 * 24));
  const threadAgeLabel = timediff === 0 ? copy.today : timediff + (timediff > 1 ? ` ${copy.days_ago}` : ` ${copy.day_ago}`);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const canedit = loggedIn && (id == thread.author_id || forumAdmin);

  return (
    <div className="commentBox" onClick={() => navigate(`/study/${studyid}/forum/topic/${thread.id}`)} style={{ position: "relative" }}>
      {canedit && (
        <button
          className="comment_menu"
          onClick={e => {
            e.stopPropagation();
            setShowMenu(true);
          }}
        >
          <img src={ComentMenu} alt="Coment Menu" />
        </button>
      )}
      <img src={thread.author_avatar} alt="ProfilePic" className="postuser" />
      <div className="discu_user">
        <h4>{thread.author_name}</h4>
        <div className="postDate">{threadAgeLabel}</div>
        <p>{thread.title}</p>
        <div className="like_reply">
          {/* <div className="like_box">
            <img src={heart} alt="Liked" className="LikeIcon" />
            <span>Liked</span>
          </div> */}
          <div className="coment_box">
            <img src={comment} alt="comment" className="CommentIcon" />
            <span>
              {thread.reply_count} {thread.reply_count === 0 ? copy.x_replies : thread.reply_count > 1 ? copy.x_replies : copy["1_reply"]}
            </span>
          </div>
        </div>
        {showMenu && canedit && (
          <div className="miniMenu" style={{ position: "absolute", top: 0, right: 0 }} onMouseLeave={() => setShowMenu(false)}>
            <button
              className="btnMiniDark mb10"
              onClick={e => {
                e.stopPropagation();
                setShowMenu(false);
                onDelete(thread.id);
              }}
            >
              delete
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default StudyForumThreadItem;
