import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { SetPersonalData } from "../api/AppApi";
import { AppActions } from "../store/AppSlice";
import { store } from "../store/store";
import { RefreshUserData } from "../store/UserSlice";
import ErrorList from "./ErrorList";
import QuestionForm from "./QuestionForm";

function StudyParticipateEligibility(props) {
  const { study, onComplete, pdqs } = props;
  const { copy } = useSelector(state => state);
  const { personalDataAnswers } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(false);
  const location = useLocation();

  const answered = pdqs.reduce((arr, pdq) => {
    arr.push(...pdq.questions);
    return arr;
  }, []);
  const eligibilityQuestions = study.questions_missing.reduce((arr, questionid) => {
    const q = answered.find(pdq => pdq.id === questionid);
    if (q) arr.push(q);
    return arr;
  }, []);

  const onFormSubmit = formResponse => {
    const { userToken } = store.getState().user;
    const parsedData = Array.isArray(personalDataAnswers) ? [...personalDataAnswers.map(a => ({ ...a }))] : [];
    formResponse.forEach(entry => {
      const existingEntry = parsedData.length ? parsedData.find(e => e.id === entry.id) : false;
      if (existingEntry) {
        existingEntry.value = entry.value;
        if (entry.extra) existingEntry.extra = entry.extra;
      } else {
        parsedData.push(entry);
      }
    });
    dispatch(AppActions.setAppState("processing"));
    SetPersonalData(parsedData, userToken).then(response => {
      if (response.showErrors) {
        dispatch(AppActions.setShowErrors(response.showErrors));
        dispatch(AppActions.setAppState("idle"));
      } else if (response.loginRequired) {
        location.state = { onLogin: () => onFormSubmit(formResponse) };
        dispatch(AppActions.setLoginOverlay(true));
        dispatch(AppActions.setAppState("idle"));
      } else if (response.errors) {
        setErrors(response.errors);
        dispatch(AppActions.setAppState("idle"));
      } else {
        dispatch(RefreshUserData()).then(r => {
          if (r.errors) setErrors(r.errors);
          else {
            setErrors(false);
            onComplete();
          }
          dispatch(AppActions.setAppState("idle"));
        });
      }
    });
  };

  return (
    <section className="eligiglityContainer">
      <div className="whiteBox ml-15 mr-15">
        <h2 className="mb10">{copy.eligibility_criteria_title}</h2>
        <p>{copy.eligibility_text}</p>
      </div>
      <br />
      <h3 className="mb0">{copy.details_required_title}</h3>
      <br />
      <br />
      <div className="whiteBox ml-15 mr-15">
        <p>{study.details_needed_text}</p>
      </div>
      <div className="selectyear p15">
        <QuestionForm questions={eligibilityQuestions} onSubmit={onFormSubmit} acceptTerms={copy.legal_hyperlink_text} />
        <ErrorList errors={errors} />
      </div>
    </section>
  );
}

export default StudyParticipateEligibility;
