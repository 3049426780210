import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchStudiesBox from "../components/SearchStudiesBox";
import StudyBox from "../components/StudyBox";

function PageHome() {
  const navigate = useNavigate();
  const { latestStudies } = useSelector(state => state.app);
  const { copy } = useSelector(state => state);
  const { loggedIn, participatingSurveys, privateStudies } = useSelector(state => state.user);

  let userStudies = <></>;
  if (participatingSurveys?.length || privateStudies?.length) {
    userStudies = [];
    if (participatingSurveys)
      userStudies.push(
        [...participatingSurveys]
          .sort((a, b) => (a.surveyComplete == b.surveyComplete ? 0 : a.surveyComplete ? 1 : -1))
          .map((study, i) => <StudyBox key={study.id} study={study} selectable={"user"} />)
      );
    if (privateStudies)
      userStudies.push(
        ...privateStudies.filter(s => !s.is_participating).map((study, i) => <StudyBox key={study.id} study={study} selectable={"participating"} />)
      );
  } else {
    userStudies = <p className="fz20">{copy.no_studies_results_body}</p>;
  }

  return (
    <section className="screen">
      <div className="screen-body container">
        <section className="section_area">
          <div className="container">
            {loggedIn && (
              <>
                <h2 className="toptile">{copy.your_studies_title}</h2>
                <div className=" mb15">
                  {userStudies}
                  {/* <SearchStudiesBox /> */}
                </div>
              </>
            )}
            {/* {!loggedIn && (
              <div className="studies_search_area">
                <SearchStudiesBox />
              </div>
            )} */}
          </div>
        </section>
        <section className="container">
          <h2>Latest Studies</h2>
          {latestStudies.map((study, i) => {
            return <StudyBox key={i} study={study} selectable />;
          })}
        </section>

        <div className="studiesBtn text-center">
          <h2 className="text-center">
            <a className="cd underline" onClick={() => navigate("/studies")}>
              View all studies
            </a>
          </h2>
          <a className="fz20 cd underline" onClick={() => navigate("/studies/addprivate")}>
            Click here if you have <br />a private study code
          </a>
        </div>
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageHome;
