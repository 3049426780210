import React from "react";
import { useSelector } from "react-redux";

function TermsOfUsePopup(props) {
  const { onClose } = props;
  const { legal_box_title, legal_box_copy_title } = useSelector(state => state.copy);
  return (
    <div className="termsOfUserContainer">
      <a className="closePopup" onClick={onClose}>
        <i className="fas fa-times"></i>
      </a>
      <div className="termsOfUsePopup">
        <h3 className="fz22">
          <b>{legal_box_title}</b>
        </h3>
        <br />
        <br />
        <p dangerouslySetInnerHTML={{ __html: legal_box_copy_title }}></p>
      </div>
    </div>
  );
}

export default TermsOfUsePopup;
