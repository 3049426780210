import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteForumResponse, GetForumThread, GetForumThreadPosts } from "../api/AppApi";
import { AppActions } from "../store/AppSlice";
import Pencil from "../img/pencil-alt-solid.svg";
import StudyForumThreadReply from "../components/StudyForumThreadReply";
import StudyBox from "../components/StudyBox";
import StudyForumThreadItem from "../components/StudyForumThreadItem";
import ErrorList from "../components/ErrorList";

function PageForumThread() {
  const { studyid, threadid } = useParams();
  const { studies } = useSelector(state => state.app);
  const { userToken } = useSelector(state => state.user);
  const study = studies.find(s => s.id == studyid);
  const { copy } = useSelector(state => state);
  const dispatch = useDispatch();
  const [threadData, setThreadData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [threadPosts, setThreadPosts] = useState([]);
  const [threadPostsPage, setThreadPostsPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(AppActions.setAppState("processing"));
    GetForumThread(threadid).then(response => {
      if (response.showErrors) {
        dispatch(AppActions.setShowErrors(response.showErrors));
      } else if (response.errors) setErrors(response.errors);
      else {
        setErrors(false);
        setThreadData(response.data);
        loadPosts(1);
      }
      dispatch(AppActions.setAppState("idle"));
    });
  }, []);

  const loadPosts = page => {
    dispatch(AppActions.setAppState("processing"));
    GetForumThreadPosts(threadid, page).then(response => {
      if (response.showErrors) {
        dispatch(AppActions.setShowErrors(response.showErrors));
      } else if (response.errors) setErrors(response.errors);
      else {
        setErrors(false);
        const results = response.data.filter(r => !threadPosts.find(p => p.id === r.id));
        const current = threadPosts.filter(p => !results.find(r => p.id == r.id));
        setThreadPosts([...current, ...results]);
        setThreadPostsPage(page);
      }
      dispatch(AppActions.setAppState("idle"));
    });
  };

  const handleDeletePost = postid => {
    dispatch(AppActions.setAppState("processing"));
    DeleteForumResponse(postid, userToken).then(response => {
      if (response.showErrors) {
        dispatch(AppActions.setShowErrors(response.showErrors));
      } else if (response.errors) setErrors(response.errors);
      else if (response.id === postid) {
        setThreadPosts(threadPosts.filter(p => p.id != postid));
      }
      dispatch(AppActions.setAppState("idle"));
    });
  };

  const handleEditPost = postid => {
    navigate(`/study/${studyid}/forum/topic/edit`, { state: { post: threadPosts.find(p => p.id == postid), thread: threadData } });
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        <h2 className="text-center toptile">{copy.forum_title}</h2>
        <StudyBox study={study} />
        <h2 className="mb10">{copy.post_activity_title || "post_activity_title : not found"}</h2>
        {threadData && <StudyForumThreadItem thread={threadData} studyid={studyid} />}
        <div className="coment_replayBox mb15">
          {errors && <ErrorList errors={errors} />}
          {threadPosts.map((post, i) => (
            <StudyForumThreadReply key={i} post={post} onDelete={handleDeletePost} onEdit={handleEditPost} />
          ))}
        </div>

        <div className="dualBtn mb15">
          {threadPosts.length < threadData.reply_count && (
            <a className="white_button" onClick={() => loadPosts(threadPostsPage + 1)}>
              show more comments
            </a>
          )}
          <a className="white_button" onClick={() => navigate(`/study/${studyid}/forum/topic/${threadid}/reply`, { state: { thread: threadData } })}>
            Post a reply
            <span className="editPencil">
              <img src={Pencil} alt="Pencil" />
            </span>
          </a>
          <a className="white_button" onClick={() => navigate(`/study/${studyid}/forum`)}>
            Back to forum
          </a>
        </div>
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageForumThread;
