import { useRef, useState } from "react";
import { Camera, CameraResultType } from "@capacitor/camera";

const style = { width: "95%", minHeight: "50px" };

function Question(props) {
  const { data, showError } = props;
  let body = <></>;
  switch (data.type) {
    case "input":
      body = <TextQuestion data={data} showError={showError} />;
      break;
    case "text":
      body = <TextFieldQuestion data={data} showError={showError} />;
      break;
    case "number":
      body = <NumberQuestion data={data} showError={showError} />;
      break;
    case "date":
      body = <DateQuestion data={data} showError={showError} />;
      break;
    case "boolean":
    case "boolean_details":
      body = <Boolean data={data} showError={showError} />;
      break;
    case "scale":
      body = <Range data={data} showError={showError} />;
      break;
    case "options":
      body = <Options data={data} showError={showError} />;
      break;
    case "image":
      body = <ImageUpload data={data} showError={showError} />;
      break;
    case "audio":
    case "file":
      body = <FileUpload data={data} showError={showError} />;
      break;
  }

  return (
    <div className="whiteBox boRa mb10 questionBox text-center">
      <h2 className="mb10">{data.name}</h2>
      <p className="mb10 fz20">{data.description}</p>
      {body}
      {showError && (
        <p className="form_error" style={{ textAlign: "center" }}>
          {showError}
        </p>
      )}
    </div>
  );
}

export default Question;

export const TextQuestion = props => {
  const { data, showError } = props;
  const [value, setValue] = useState(data.value || "");
  const handleInput = e => setValue(e.target.value);
  return <input type="text" name={data.id} style={style} value={value} onChange={handleInput} />;
};

function TextFieldQuestion(props) {
  const { data, showError } = props;
  return <textarea name={data.id} rows={data.rows || "5"} style={style} />;
}

function NumberQuestion(props) {
  const { data } = props;
  const [value, setValue] = useState("");
  const onChange = e => {
    const tValue = e.target.value;
    if (tValue.includes(".")) return setValue(value);
    if (!/^[0-9]/.test(e.target.value)) setValue(value);
    let val = e.target.value ? Math.max(0, e.target.value) : e.target.value;
    if (data.minimum) val = Math.max(parseFloat(data.minimum), val);
    if (data.maximum) val = Math.min(parseFloat(data.maximum), val);
    setValue(val);
  };
  return (
    <input
      datatype={"number"}
      name={data.id}
      type="number"
      value={value}
      min={parseFloat(data.minimum) || 0}
      max={parseFloat(data.maximum) || undefined}
      step={1}
      style={style}
      onChange={onChange}
    />
  );
}

function DateQuestion(props) {
  const { data } = props;
  return (
    <div className="dateSelect">
      <input name={data.id} type="date" />
    </div>
  );
}

function Range(props) {
  const { data } = props;
  const [value, setValue] = useState(50);
  const position = ((value - 1) / 99) * 100;
  const offset = position > 50 ? ((position - 50) / 50) * -13 : ((50 - position) / 50) * 15;
  return (
    <>
      <div className="selectRange">
        <p>1</p>
        <div style={{ position: "relative", width: "100%", margin: "0px 10px" }}>
          <div className="selectRange--value" style={{ left: `calc(${position}% + ${offset}px)` }}>
            <p>{value}</p>
          </div>
          <input name={data.id} datatype={"number"} type="range" min="1" max="100" step="1" value={value} onChange={e => setValue(e.target.value)} />
        </div>
        <p>100</p>
      </div>
    </>
  );
}

function Boolean(props) {
  const { data } = props;
  const [text, setText] = useState(data.type === "boolean_details" || data.show_details_when == "both_mandatory");
  const { inputYes } = useRef();
  const { inputNo } = useRef();

  const onChange = e => {
    if (data.type === "boolean_details") {
      setText(true);
    } else {
      switch (data.show_details_when) {
        case "none":
          setText(false);
          break;
        case "both_mandatory":
        case "both_optional":
          setText(true);
          break;
        case "yes_optional":
        case "yes_mandatory":
          setText(e.target.value === "true");
          break;
        case "no_mandatory":
        case "no_optional":
          setText(e.target.value === "false");
          break;
      }
    }
  };

  return (
    <div className="radio--field">
      <div>
        <label htmlFor={data.id} className="radio">
          <input ref={inputYes} type="radio" datatype={"boolean"} name={data.id} value="true" onChange={onChange} className="radio--input" />
          <div className="radio--radio">Yes</div>
        </label>
      </div>
      <div>
        <label htmlFor={data.id} className="radio">
          <input ref={inputNo} type="radio" datatype={"boolean"} name={data.id} value="false" onChange={onChange} className="radio--input" />
          <div className="radio--radio">No</div>
        </label>
      </div>
      {text && (
        <>
          <p>{data.details_text}</p>
          <textarea name={data.id + "_details"} rows="5" style={style} />
        </>
      )}
    </div>
  );
}

function Options(props) {
  const { data } = props;

  return (
    <div className="radio--field">
      {Object.keys(data.options).map((key, i) => {
        return (
          <label htmlFor={data.id} className="radio" key={i}>
            <input type="radio" name={data.id} value={key} className="radio--input" />
            <div className="radio--radio"> {data.options[key]} </div>
          </label>
        );
      })}
    </div>
  );
}

function FileUpload(props) {
  const { data } = props;
  const [value, setValue] = useState(false);
  const [filename, setFileName] = useState(false);

  const handleUpload = e => {
    if (!e.target.value) setValue(false);
    else {
      if (e.target.value != value) {
        const FR = new FileReader();
        FR.addEventListener("load", function (evt) {
          setValue(evt.target.result);
        });
        setFileName(e.target.files[0].name);
        FR.readAsDataURL(e.target.files[0]);
      }
    }
  };

  return (
    <>
      <label htmlFor={data.id} className="fileupload">
        <input type="hidden" name={data.id} value={value} />
        <input type="file" accept={`${data.type}/*`} onChange={handleUpload} />
        <span className={filename ? "fileupload--display active" : "fileupload--display"}>{filename ? "file : " + filename : "choose file"}</span>
      </label>
    </>
  );
}

function ImageUpload(props) {
  const { data } = props;
  const [value, setValue] = useState("false");
  const [filename, setFileName] = useState(false);

  if (Camera) {
    const getPhoto = async () => {
      const photo = await Camera.getPhoto({
        allowEditing: true,
        resultType: CameraResultType.Base64,
        saveToGallery: false
      });

      setValue("data:image/" + photo.format + ";base64," + photo.base64String || false);
    };

    return (
      <>
        <div className="mb15" style={{ width: "100%" }}>
          {value !== "false" && <img src={value} style={{ maxWidth: "50%", maxHeight: "200px", margin: "auto" }} />}
        </div>
        <label htmlFor={data.id} className="fileupload">
          <input type="hidden" name={data.id} value={value} />
          <span className={filename ? "fileupload--display active" : "fileupload--display"} onClick={getPhoto}>
            {data.label ? data.label : filename ? "file : " + filename : "choose file"}
          </span>
        </label>
      </>
    );
  }
  const handleUpload = e => {
    if (!e.target.value) setValue(false);
    else {
      if (e.target.value != value) {
        const FR = new FileReader();
        FR.addEventListener("load", function (evt) {
          setValue(evt.target.result);
        });
        setFileName(e.target.files[0].name);
        FR.readAsDataURL(e.target.files[0]);
      }
    }
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <img src={value} width="50%" margin="auto" />
      </div>
      <label htmlFor={data.id} className="fileupload">
        <input type="hidden" name={data.id} value={value} />
        <input type="file" accept={`${data.type}/*`} onChange={handleUpload} />
        <span className={filename ? "fileupload--display active" : "fileupload--display"}>{filename ? "file : " + filename : "choose file"}</span>
      </label>
    </>
  );
}
