import NavigationHeader from "./components/NavigationHeader";
import NavigationFooter from "./components/NavigationFooter";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "./components/LoadingScreen";
import { Route, Routes, useNavigate, Outlet, useLocation, useNavigationType } from "react-router-dom";
import PageIntro from "./pages/PageIntro";
import PageHome from "./pages/PageHome";
import PageStudies from "./pages/PageStudies";
import PageSearch from "./pages/PageSearch";
import PageStudy from "./pages/PageStudy";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PageStudyParticipate from "./pages/PageStudyParticipate";
import PageLogin from "./pages/PageLogin";
import PageStudyUser from "./pages/PageStudyUser";
import PageStudiesBy from "./pages/PageStudiesAuthor";
import PageStudyUserSchedule from "./pages/PageStudyUserSchedule";
import PageStudyUserConfirmed from "./pages/PageStudyUserConfirmed";
import PageStudyUserLogActivity from "./pages/PageStudyUserLogActivity";
import PageStudyUserProgress from "./pages/PageStudyUserProgress";
import PageAccount from "./pages/PageAccount";
import RouteStudyForum from "./routes/RouteStudyForum";
import PageStudyAddPrivate from "./pages/PageStudyAddPrivate";
import PageStudyUserNotifications from "./pages/PageStudyUserNotifications";
import ErrorList from "./components/ErrorList";
import { AppActions, LoadAppData } from "./store/AppSlice";
import PageAccountEdit from "./pages/PageAccountEdit";
import PageAccountSettings from "./pages/PageAccountSettings";
import PageErrorPopup from "./pages/PageErrorPopup";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const navType = useNavigationType();
  const main = useRef();
  const { state, initialized, bootError, loginOverlay, showErrors } = useSelector(state => state.app);

  useEffect(() => {
    if (!initialized) {
      dispatch(LoadAppData()).then(async response => {
        if (location.pathname === "/") {
          if (response.payload?.user?.loggedIn) {
            navigate("account");
          } else {
            navigate("intro");
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (main.current) main.current.scrollTo(0, 0);
    if (loginOverlay) dispatch(AppActions.setLoginOverlay(false));
  }, [location]);

  if ((!initialized || state === "boot") && !bootError) return <LoadingScreen />;

  return (
    <div className="app">
      {state === "processing" && <LoadingScreen />}

      <div className="app-header">
        <NavigationHeader />
      </div>

      {loginOverlay && (
        <div className="app-body main_container" ref={main}>
          <PageLogin noRedirect={true} />
        </div>
      )}

      <div
        className="app-body main_container"
        ref={main}
        style={{ visibility: loginOverlay ? "hidden" : "visible", height: loginOverlay ? 0 : "auto" }}
      >
        {bootError && <ErrorList errors={bootError} />}
        {!bootError && (
          <Routes>
            <Route path="intro">
              <Route index element={<PageIntro />} />
              <Route path="/intro/:page" element={<PageIntro />} />
            </Route>
            <Route exact path="/" element={<PageHome />} />
            <Route path="/login" element={<PageLogin />} />
            <Route path="/studies" element={<PageStudies />} />
            <Route path="/studies/addprivate" element={<PageStudyAddPrivate />} />
            <Route path="/studies/:studyid" element={<PageStudiesBy />} />
            <Route path="/study/:studyid" element={<PageStudy />} />
            <Route path="/study/:studyid/forum/*" element={<RouteStudyForum />} />

            <Route path="/searchstudies">
              <Route index element={<PageSearch />} />
              <Route path="/searchstudies/:params" element={<PageSearch />} />
            </Route>
            <Route element={<ProtectedRoutes />}>
              <Route path="/account" element={<PageAccount />} />
              <Route path="/account/profile" element={<PageAccountEdit />} />
              <Route path="/account/settings" element={<PageAccountSettings />} />
              <Route path="/study/:studyid/participate" element={<PageStudyParticipate />} />
              <Route path="/study/:studyid/user" element={<PageStudyUser />} />
              <Route path="/study/:studyid/user/confirmed" element={<PageStudyUserConfirmed />} />
              <Route path="/study/:studyid/user/schedule" element={<PageStudyUserSchedule />} />
              <Route path="/study/:studyid/user/logactivity" element={<PageStudyUserLogActivity />} />
              <Route path="/study/:studyid/user/progress" element={<PageStudyUserProgress />} />
              <Route path="/study/:studyid/user/notifications" element={<PageStudyUserNotifications />} />
            </Route>
          </Routes>
        )}
      </div>
      <div className="app-footer">
        <NavigationFooter />
      </div>
      {showErrors && <PageErrorPopup />}
    </div>
  );
}

export default App;
