import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StudyBox from "../components/StudyBox";

function PageStudy() {
  const { studyid } = useParams();
  const { studies } = useSelector(state => state.app);
  const { participatingSurveys } = useSelector(store => store.user);
  const participating = participatingSurveys.find(s => s.id == studyid);
  const { privateStudies } = useSelector(state => state.user);
  const study = [...studies, ...privateStudies].find(s => s.id == studyid);
  const navigate = useNavigate();

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        <div className="container">
          <StudyBox study={study} />
          <h2 className="title_underline">About The Study</h2>
          <div className="whiteBox ml-15 mr-15">
            <p>{study.about_text}</p>
          </div>
          <br />
          <br />
          <h2 className="title_underline">About {study.user}</h2>
          <div className="whiteBox ml-15 mr-15">
            <p>{study.user_biography}</p>
          </div>
          <div className="studyBottom">
            <h3 style={{ cursor: "pointer" }} className="secTitleUnder" onClick={() => navigate(`/studies/${studyid}`)}>
              View all studies by {study.user}
            </h3>
            {!participating && (
              <a className="nextBtn" onClick={() => navigate(`/study/${studyid}/participate`)}>
                Take Part
              </a>
            )}
            <a className="nextBtn" onClick={() => navigate(`/study/${studyid}/forum`)}>
              View Forum
            </a>
          </div>
        </div>
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageStudy;
