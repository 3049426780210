import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToDateText } from "../api/AppApi";
import StudyBox from "../components/StudyBox";

function PageStudyUserConfirmed() {
  const { studyid } = useParams();
  const navigate = useNavigate();
  const { participatingSurveys } = useSelector(state => state.user);
  const { copy } = useSelector(state => state);
  const study = participatingSurveys.find(s => s.id == studyid);

  useEffect(() => {
    if (!study) navigate("/");
    if (todaysDate <= startDate) navigate(`/study/${studyid}/user/schedule`, { replace: true });
  }, []);

  if (!study) return <></>;

  const todaysDate = new Date(new Date().setHours(0, 0, 0, 0));
  const startDate = new Date(study.start_participation_at);

  return (
    <section className="screen">
      <div className="screen-body">
        <StudyBox study={study} />
        <div className="contentBox p15 mb15">
          <h3 className="mb15">{copy.confirmation_title}</h3>
          <p className="mb15">
            {copy.confirmation_start_copy_body} {ToDateText(startDate)}
          </p>
          <p className="mb15" dangerouslySetInnerHTML={{ __html: study.whats_required_text }}></p>
        </div>
        <div className="nex_btn text-center">
          <p className="mb15">{copy.schedule_hint_text}</p>
          <a className="nextBtn" onClick={() => navigate(`/study/${studyid}/user/schedule`, { replace: true })}>
            {copy.schedule_button}
          </a>
        </div>
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageStudyUserConfirmed;
