import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostForumTopic } from "../api/AppApi";
import ErrorList from "../components/ErrorList";
import { AppActions } from "../store/AppSlice";
import { store } from "../store/store";

function PageForumThreadNew() {
  const { studyid } = useParams();
  const { copy } = useSelector(state => state);
  // const { userToken } = useSelector(state => state.user);
  const { studies } = useSelector(state => state.app);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(false);
  const [newThread, setNewThread] = useState("idle");
  const study = studies.find(s => s.id == studyid);
  const titleBox = useRef(false);
  const commentBox = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const validate = () => {
    const { userToken } = store.getState().user;
    if (commentBox.current.value.length >= 3 && titleBox.current.value.length >= 3) {
      dispatch(AppActions.setAppState("processing"));
      PostForumTopic(study.forum_category_id, { title: titleBox.current.value, content: commentBox.current.value }, userToken).then(response => {
        if (response.showErrors) {
          dispatch(AppActions.setShowErrors(response.showErrors));
        } else if (response.loginRequired) {
          location.state = { onLogin: validate };
          dispatch(AppActions.setLoginOverlay(true));
        } else if (response?.data) {
          setNewThread(response.data);
          setSubmitted(true);
        } else if (response.errors) {
          setErrors(response.errors);
        } else {
          setErrors(["an error occured sumbitting your topic, please try again"]);
        }
        dispatch(AppActions.setAppState("idle"));
      });
    } else {
      let validationErrors = [];
      if (titleBox.current.value.length < 3) validationErrors.push(copy.topic_comment_input_field_error);
      if (commentBox.current.value.length < 3) validationErrors.push(copy.topic_title_input_field_error);
      setErrors(validationErrors);
    }
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        {submitted ? (
          <>
            <div className="commentSuccessbox">
              <h2>{copy.thanks_for_topic_text}</h2>
            </div>
            <div className="dualBtn">
              <a className="nextBtn" onClick={() => navigate(`/study/${studyid}/forum/topic/${newThread.id}`, { replace: true })}>
                {copy.view}
              </a>
            </div>
          </>
        ) : (
          <>
            <h2 className="mb10">{copy.post_topic_title}</h2>
            <p>{copy.post_topic_body}</p>
            <div className="comentpostBox mb15" style={{ marginTop: 15 }}>
              <form action="#">
                <h4>{copy.enter_topic_here_text}</h4>
                <p>{copy.topic_title_input_field_label}</p>
                <input
                  ref={titleBox}
                  type="text"
                  className="mb15"
                  name="title"
                  id="title"
                  placeholder={copy.tap_to_type_hint}
                  style={{ width: "100%", padding: 10 }}
                />

                <p>{copy.topic_comment_input_field_label}</p>
                <textarea ref={commentBox} name="comment" id="comment" placeholder={copy.tap_to_type_hint}></textarea>
                {errors?.length > 0 && <ErrorList errors={errors} />}
              </form>
            </div>
            <div className="dualBtn">
              <a className="nextBtn" onClick={validate}>
                {copy.submit_button_text}
              </a>
              <a className="nextBtn" onClick={() => navigate(`/study/${studyid}/forum`, { replace: true })}>
                {copy.back_to_forum}
              </a>
            </div>
          </>
        )}
      </div>
      <div className="screen-footer"></div>
    </section>
  );
}

export default PageForumThreadNew;
