export const SortStudies = (studies, order = "latest") => {
  switch (order) {
    case "latest":
      studies.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      break;
    case "oldest":
      studies.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      break;
    case "alphanumeric":
      studies.sort((a, b) => {
        const isNumberA = !isNaN(a.name.charAt(0));
        const isNumberB = !isNaN(b.name.charAt(0));
        if (isNumberA && isNumberB) {
          const numberA = a.name.slice(
            0,
            a.name.split("").findIndex(char => isNaN(char))
          );
          const numberB = b.name.slice(
            0,
            a.name.split("").findIndex(char => isNaN(char))
          );
          return numberA - numberB;
        }
        if (isNumberA || isNumberB) return isNumberA ? 1 : -1;
        return a.name.localeCompare(b.name);
      });
      break;
    case "numericalpha":
      studies.sort((a, b) => {
        const isNumberA = !isNaN(a.name.charAt(0));
        const isNumberB = !isNaN(b.name.charAt(0));
        if (isNumberA && isNumberB) {
          const numberA = a.name.slice(
            0,
            a.name.split("").findIndex(char => isNaN(char))
          );
          const numberB = b.name.slice(
            0,
            a.name.split("").findIndex(char => isNaN(char))
          );
          return numberA - numberB;
        }
        if (isNumberA || isNumberB) return isNumberA ? -1 : 1;

        return a.name.localeCompare(b.name);
      });
      break;
  }

  return studies;
};
