import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PageLogin from "../pages/PageLogin";

function ProtectedRoutes() {
  const location = useLocation();
  const { loggedIn } = useSelector(state => state.user);
  useEffect(() => {}, []);
  return <>{loggedIn ? <Outlet /> : <Navigate to="/login" replace state={{ from: location }} />}</>;
}

export default ProtectedRoutes;
