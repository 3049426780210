import { configureStore } from "@reduxjs/toolkit";
import { AppReducer } from "./AppSlice";
import { CopyReducer } from "./CopySlice";
import { UserReducer } from "./UserSlice";

export const store = configureStore({
  reducer: {
    app: AppReducer,
    copy: CopyReducer,
    user: UserReducer
  }
});
