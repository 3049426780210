import { useRef, useState } from "react";
import TermsOfUsePopup from "./TermsOfUsePopup";
import checksolid from "../img/check-solid.svg";
import Question from "./Question";
import { useSelector } from "react-redux";

function QuestionForm(props) {
  const { questions, acceptTerms, onSubmit } = props;
  const [showTerms, setShowTerms] = useState(false);
  const [errors, setErrors] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const { copy } = useSelector(state => state.copy);
  const termsField = useRef();
  const form = useRef();

  const validate = e => {
    e.preventDefault();
    const results = validateForm(questions, form.current, copy);
    const termsvalid = acceptTerms ? termsField.current.checked : true;
    if (!results.errors.length && termsvalid) {
      setErrors([]);
      onSubmit(results.data);
    } else setErrors(results.errors.length ? results.errors : false);
    if (!termsvalid) setTermsError("please accept terms");
    else setTermsError(false);
  };
  return (
    <>
      {showTerms && <TermsOfUsePopup onClose={() => setShowTerms(false)} />}
      <form ref={form} action="" className="mb15">
        {questions && questions.map((q, i) => <Question key={i} data={q} showError={errors && errors.find(e => e.id == q.id)?.error} />)}
        {acceptTerms && (
          <div className="checkAggree mb15">
            <br />
            <div style={{ display: "flex", alignItems: "center" }}>
              <input ref={termsField} type="checkbox" name="terms" id="terms" />
              <label htmlFor="terms">
                <span>
                  <img src={checksolid} alt="checksolid" />
                </span>
              </label>
              <a onClick={() => setShowTerms(true)}>{acceptTerms}</a>
            </div>
            {termsError && (
              <p className="form_error" style={{ textAlign: "center" }}>
                {termsError}
              </p>
            )}
            <br />
          </div>
        )}
        <div className="nex_btn text-center">
          <br />
          <button type="submit" href="#" className="nextBtn" onClick={validate}>
            Next
          </button>
        </div>
      </form>
    </>
  );
}

export default QuestionForm;

const validateForm = (questions, form, copy) => {
  const errors = [];
  const data = [];
  const formData = new FormData(form);
  questions.forEach(question => {
    const answer = formData.get(question.id);
    const details = formData.get(`${question.id}_details`);
    let valid;
    switch (question.type) {
      case "text":
        valid = validateTextQuestion(question, answer, details, copy);
        break;
      case "number":
        valid = validateNumberQuestion(question, answer, details, copy);
        break;
      case "scale":
        valid = validateScaleQuestion(question, answer, details, copy);
        break;
      case "options":
        valid = validateOptionQuestion(question, answer, details, copy);
        break;
      case "boolean":
      case "boolean_details":
        valid = validateBooleanQuestion(question, answer, details, copy);
        break;
      case "date":
        valid = validateDateQuestion(question, answer, details, copy);
        break;
      case "image":
      case "audio":
      case "file":
        valid = ValidateFileQuestion(question, answer, details, copy);
        break;
    }
    if (valid?.data) data.push({ id: question.id, ...valid.data });
    if (valid?.error) errors.push({ id: question.id, error: valid.error });
  });
  return { errors, data };
};

const validateTextQuestion = (question, answer, details, copy) => {
  if (!answer) return { error: "please enter some text" };
  return { error: false, data: { value: answer } };
};
const validateOptionQuestion = (question, answer) => {
  if (!answer) return { error: "please select an option" };
  return { error: false, data: { value: answer } };
};
const validateDateQuestion = (question, answer) => {
  if (!answer) return { error: "please select a date" };
  return { error: false, data: { value: answer } };
};

const ValidateFileQuestion = (question, answer) => {
  if (!answer || answer === "false") return { error: question.type == "file" ? "please upload file" : `please upload ${question.type} file` };
  return { error: false, data: { value: answer } };
};

const validateNumberQuestion = (question, answer) => {
  if (!answer) {
    if (question.hasOwnProperty("minimum") && question.maximum) {
      return { error: `please enter a number between ${question.minimum} and ${question.maximum}` };
    }
    return { error: "please enter a number" };
  }
  return { error: false, data: { value: answer } };
};

const validateScaleQuestion = (question, answer) => {
  if (!answer) {
    return { error: `please select a number between ${question.minimum} and ${question.maximum}` };
  }
  return { error: false, data: { value: answer } };
};

const validateBooleanQuestion = (question, answer, details) => {
  if (!answer) return { error: "please select an option", value: false };
  if (question.type === "boolean_details") {
    if (!details) return { error: "please add details", value: false };
  } else {
    switch (question.show_details_when) {
      case "none":
        break;
      case "both_mandatory":
        if (!details) return { error: "please add details", value: false };
        break;
      case "yes_mandatory":
        if (!details && answer == "true") return { error: "please add details", value: false };
        break;
      case "no_mandatory":
        if (!details && answer == "false") return { error: "please add details", value: false };
        break;
    }
  }

  return { error: false, data: { value: answer, extra: details || false } };
};
