import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Register, ValidateEmail, ValidatePass } from "../api/AppApi";
import Eye from "../img/eye-regular.svg";
import EyeSlash from "../img/eye-slash-regular.svg";
import checkIcon from "../img/check-solid.svg";
import ErrorList from "./ErrorList";
import TermsOfUsePopup from "./TermsOfUsePopup";
import { AppActions } from "../store/AppSlice";

function AccountCreate(props) {
  const dispatch = useDispatch();
  const { onComplete, onCancel } = props;
  const [page, setPage] = useState(1);
  const [accountData, setAccountData] = useState({});

  const handlePageComplete = data => {
    const updatedAccountData = { ...accountData, ...data };
    setAccountData(updatedAccountData);
    switch (page) {
      case 2:
        dispatch(AppActions.setAppState("processing"));
        Register(updatedAccountData).then(response => {
          dispatch(AppActions.setAppState("idle"));
          if (response.showErrors) {
            dispatch(AppActions.setShowErrors(response.showErrors));
          } else if (response.errors) onComplete(response);
          else setPage(page + 1);
        });
        break;
      case 3:
        onComplete();
        break;
      default:
        setPage(page + 1);
        break;
    }
  };

  switch (page) {
    case 1:
      return <AccountCreateName onComplete={handlePageComplete} onCancel={onCancel} />;
    case 2:
      return <AccountCreateEmail onComplete={handlePageComplete} onCancel={onCancel} />;
    case 3:
      return <AccountCreateComplete onComplete={handlePageComplete} />;
  }
}

export default AccountCreate;

function AccountCreateName(props) {
  const { onComplete, onCancel } = props;
  const [errors, setErrors] = useState(false);
  const { copy } = useSelector(state => state);
  const fieldName = useRef();
  const fieldSurname = useRef();

  const validate = () => {
    const validationErrors = [];
    if (!fieldName.current.value.length) validationErrors.push("please input first name");
    if (!fieldSurname.current.value.length) validationErrors.push("please input surname");
    if (validationErrors.length) setErrors(validationErrors);
    else {
      setErrors(false);
      onComplete({ name: `${fieldName.current.value.trim()} ${fieldSurname.current.value.trim()}` });
    }
  };

  return (
    <>
      <h2 className="text-center toptile">{copy.create_profile_title}</h2>
      <div className="profileContentBox">
        <p>
          {copy.create_profile_body_1}
          <br />
          <br />
          {copy.create_profile_body_2}
        </p>
      </div>
      <br />
      <div className="formContainer CreateProfileForm">
        <div className="formSteep1">
          <div className="inputCol">
            <input ref={fieldName} type="text" name="FirstName" id="FirstName" placeholder="First Name" />
          </div>
          <div className="inputCol">
            <input ref={fieldSurname} type="text" name="SurName" id="SurName" placeholder="Surname" />
          </div>
        </div>
        <div className="formNext">
          <button className="next_button" onClick={validate}>
            Next
          </button>
        </div>
      </div>
      <br />
      <br />
      <ErrorList errors={errors} />
      <div className="loginHere text-center">
        <a onClick={onCancel}>Existing user? Login here</a>
      </div>
    </>
  );
}

function AccountCreateEmail(props) {
  const { onComplete, onCancel } = props;
  const { copy } = useSelector(state => state);
  const emailField = useRef();
  const passField = useRef();
  const passRepeat = useRef();
  const termsCheck = useRef();
  const [errors, setErrors] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const submit = () => {
    const emailvalid = ValidateEmail(emailField.current.value);
    const passValid = ValidatePass(passField.current.value);
    const passMatch = passField.current.value === passRepeat.current.value;
    const termsAccepted = termsCheck.current.checked;
    const validationErrors = [];
    if (!emailvalid) validationErrors.push("invalid email");
    if (!passValid) validationErrors.push("invalid password");
    if (!passMatch) validationErrors.push("passwords do not match");
    if (!termsAccepted) validationErrors.push("please accept terms");
    if (validationErrors.length) setErrors(validationErrors);
    else {
      setErrors(false);
      onComplete({ email: emailField.current.value.trim(), password: passField.current.value.trim() });
    }
  };

  return (
    <>
      {showTerms && <TermsOfUsePopup onClose={() => setShowTerms(false)} />}
      <h2 className="text-center toptile">{copy.create_profile_final_step_title}</h2>
      <div className="profileContentBox">
        <p className="fz18">
          {copy.create_profile_final_step_body_1} <br />
          <br />
          {copy.create_profile_final_step_body_2}
        </p>
      </div>
      <br />

      <div className="formSteep1">
        <div className="inputCol">
          <input ref={emailField} type="email" name="profile-Email" id="profile-Email" placeholder="Email Address" />
        </div>
        <div className="inputCol">
          <input ref={passField} type="password" name="FirstPass" id="FirstPass" placeholder="Password" />
          <img src={Eye} alt="Eye" className="inputIcon eye" />
        </div>
        <p className="inputCol">
          {copy.create_profile_create_pass_copy} <br />
          {copy.create_profile_create_rule_line_1}
          <br />
          {copy.create_profile_rule_line_2}
        </p>
        <div className="inputCol">
          <input ref={passRepeat} type="password" name="RepeatPass" id="RepeatPass" placeholder="Password repeat" />
          <img src={EyeSlash} alt="Eye" className="inputIcon eye" />
        </div>
        <br />
        <div className="checkAggree w90">
          <input ref={termsCheck} type="checkbox" name="over18" id="over18" />
          <label htmlFor="over18">
            <span>
              <img src={checkIcon} alt="checksolid" />
            </span>
            <a onClick={() => setShowTerms(true)}> {copy.create_profile_legal_checkbox_link}</a>
          </label>
        </div>
      </div>
      <ErrorList errors={errors} />
      <div className="formNext">
        <button className="next_button" onClick={submit}>
          Next
        </button>
      </div>
    </>
  );
}

function AccountCreateComplete(props) {
  const { onComplete } = props;
  const { copy } = useSelector(state => state);

  return (
    <>
      <h2 className="text-center toptile">{copy.enter_verification_title}</h2>
      <br />
      <br />
      <div className="contentBox">
        <p className="fz18">
          {copy.create_profile_verify_email_body_1}
          <br />
          <br />
          {copy.create_profile_verify_email_body_2}
        </p>
      </div>
      <br />
      <br />
      <br />
      <div className="formContainer emailVerify">
        {/* <div className="checkAggree">
          <input type="checkbox" name="newMail" id="newMail" />
          <label for="newMail">
            <span>
              <img src={checkIcon} alt="checksolid" />
            </span>
            New study emails
          </label>
        </div>
        <br />
        <div className="checkAggree">
          <input type="checkbox" name="Experiment" id="Experiment" />
          <label for="Experiment">
            <span>
              <img src={checkIcon} alt="checksolid" />
            </span>
            Experiment news
          </label>
        </div> */}
        <br />
        <br />
        <div className="formNext">
          <button className="next_button" onClick={onComplete}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}
