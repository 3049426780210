import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ClearStoredUser, GetAppSettings, GetPublicSurveys, GetStoredUser, GetUser } from "../api/AppApi";
import { SortStudies } from "../api/Utils";
import { CopyActions } from "./CopySlice";
import { LoginUser, LogoutUser, RefreshUserData, UserActions } from "./UserSlice";

const initialState = {
  state: "boot",
  processing: false,
  version: 0,
  studies: [],
  latestStudies: [],
  initialized: false,
  bootError: false,
  showErrors: false,
  loginOverlay: false
};

export const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setShowErrors: (state, action) => {
      state.showErrors = action.payload;
    },
    setBootComplete: (state, action) => {
      state.state = "idle";
      state.initialized = true;
    },
    setBootError: (state, action) => {
      state.state = "idle";
      state.initialized = true;
      state.bootError = action.payload;
    },
    setAppState: (state, action) => {
      state.state = action.payload;
    },
    setAppSettings: (state, action) => {
      state.version = action.payload.app_version;
    },
    setPublicSurveys: (state, action) => {
      state.studies = SortStudies([...action.payload]);
      state.latestStudies = state.studies.slice(0, 3);
    },
    setLoginOverlay: (state, action) => {
      state.loginOverlay = action.payload;
      state.state = "idle";
    }
  },
  extraReducers: builder => {
    builder.addCase(RefreshUserData.pending, state => {
      state.state = "processing";
    });
    builder.addCase(RefreshUserData.fulfilled, (state, action) => {
      state.studies = action.payload.surveys;
      state.loginOverlay = false;
      state.state = "idle";
    });
  }
});

export const AppActions = AppSlice.actions;
export const AppReducer = AppSlice.reducer;

export const LoadAppData = createAsyncThunk("app/load", async (data, { dispatch, getState }) => {
  dispatch(AppActions.setAppState("processing"));

  const settings = await GetAppSettings();
  const surveys = await GetPublicSurveys();
  if (settings.showErrors || surveys.showErrors) {
    dispatch(AppActions.setShowErrors(settings.showErrors || surveys.showErrors));
  } else if (settings.errors || surveys.errors) {
    dispatch(AppActions.setBootError([settings.errors || surveys.errors]));
  } else {
    dispatch(CopyActions.setCopy(settings.text_settings));
    dispatch(AppActions.setPublicSurveys(surveys.data));
    dispatch(AppActions.setAppSettings(settings.app_settings));
  }

  const userToken = await GetStoredUser();
  if (userToken) {
    const user = await GetUser(userToken);
    if (!user.errors) {
      await dispatch(
        UserActions.setUser({
          id: user.id,
          forumAdmin: user.forumAdmin,
          userName: user.name,
          userEmail: user.email,
          userToken: userToken
        })
      );

      await dispatch(RefreshUserData());
    } else ClearStoredUser();
  }

  dispatch(AppActions.setBootComplete());

  return getState();
});
