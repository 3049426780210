import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function PageIntro() {
  const navigate = useNavigate();
  const { welcome_text_title, welcome_text_body } = useSelector(state => state.copy);
  const { our_vision_title, our_vision_body } = useSelector(state => state.copy);
  const { our_mission_title, our_mission_body } = useSelector(state => state.copy);
  const { getting_started_title, getting_started_body } = useSelector(state => state.copy);

  const { page = 1 } = useParams();
  const pageIndex = page ? parseFloat(page) - 1 : 0;

  const pages = [
    [{ title: welcome_text_title, copy: welcome_text_body }],
    [
      { title: our_vision_title, copy: our_vision_body },
      { title: our_mission_title, copy: our_mission_body }
    ],
    [{ title: getting_started_title, copy: getting_started_body }]
  ];

  const handleNextPage = () => {
    if (pageIndex === pages.length - 1) navigate("/");
    else navigate(`/intro/${pageIndex + 2}`);
  };

  return (
    <section className="screen introScreenContainer">
      <div className="screen-body container">
        {pages[pageIndex].map((content, i) => {
          return (
            <div className="mb50" key={i}>
              <h2 className="title_underline">{content.title}</h2>
              <div className="textWhiteBox">
                <p>{content.copy}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="screen-footer">
        <a onClick={handleNextPage} className="nextBtn" style={{ bottom: 0 }}>
          Next
        </a>
      </div>
    </section>
  );
}

export default PageIntro;
